import React, { useState, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import { IntegrationConfigModal } from '../components/integrations/IntegrationConfigModal';
import { useIntegrationStore } from '../stores/useIntegrationStore';
import { Card } from '../ui-kit/card';

interface SecurityProduct {
  name: string;
  description: string;
  configured: boolean;
}

interface Application {
  name: string;
  description: string;
  icon?: React.ReactNode;
  logoUrl?: string;
  configured: boolean;
  products?: SecurityProduct[];
}

interface IntegratorCategory {
  title: string;
  applications: Application[];
}

const getApplicationLogo = (name: string): string => {
  const logos: Record<string, string> = {
    // Communication Apps
    'Discord': 'https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png',
    'Slack': 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Slack_icon_2019.svg/2048px-Slack_icon_2019.svg.png',
    
    // Firewall Products
    'Palo Alto Networks': 'https://companieslogo.com/img/orig/PANW_BIG-627a99f8.png?t=1720244493&download=true',
    'Cisco': 'https://logos-world.net/wp-content/uploads/2021/08/Cisco-Logo.png',
    'Fortinet': 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Fortinet_logo.svg/2560px-Fortinet_logo.svg.png',
    'Check Point': 'https://companieslogo.com/img/orig/CHKP_BIG-58eea9a0.png?t=1720244491&download=true',
    
    // EDR Products
    'Palo Alto Networks Cortex': 'https://www.paloaltonetworks.com/content/dam/pan/en_US/images/logos/brand/cortex-primary/cortex-primary.png?imbypass=on',
    'CrowdStrike': 'https://logos-world.net/wp-content/uploads/2024/10/CrowdStrike-Logo.png',
    'SentinelOne': 'https://upload.wikimedia.org/wikipedia/commons/3/33/SentinelOne_logo.svg',
    'Microsoft Defender': 'https://upload.wikimedia.org/wikipedia/commons/5/50/Windows_Defender_logo.svg',
    
    // SIEM Products
    'Splunk': 'https://upload.wikimedia.org/wikipedia/commons/1/1d/Splunk_logo.svg',
    'IBM QRadar': 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/IBM_logo.svg/2560px-IBM_logo.svg.png',
    'Exabeam': 'https://logodix.com/download/1857046',
    
    // Cloud Security Services
    'AWS Security Hub': 'https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg',
    'Azure Security Center': 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Microsoft_Azure.svg',
    'GCP Security Command Center': 'https://upload.wikimedia.org/wikipedia/commons/5/51/Google_Cloud_logo.svg',
  };

  return logos[name] || '';
};

const IntegratorsPage: React.FC = () => {
  const [expandedApps, setExpandedApps] = useState<string[]>([]);
  const [selectedIntegration, setSelectedIntegration] = useState<{
    type: string;
    name: string;
  } | null>(null);
  const { configs, fetchConfigs } = useIntegrationStore();

  useEffect(() => {
    fetchConfigs();
  }, [fetchConfigs]);

  const toggleExpand = (appName: string) => {
    setExpandedApps(prev => 
      prev.includes(appName) 
        ? prev.filter(name => name !== appName)
        : [...prev, appName]
    );
  };

  const handleSelectClick = (type: string, name: string) => {
    setSelectedIntegration({ type, name });
  };

  const categories: IntegratorCategory[] = [
    {
      title: "Communication Applications",
      applications: [
        {
          name: "Discord",
          description: "Configure Discord webhook integration for notifications and alerts",
          logoUrl: 'https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png',
          configured: false
        },
        {
          name: "Slack",
          description: "Set up Slack integration for team communications and alerts",
          logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Slack_icon_2019.svg/2048px-Slack_icon_2019.svg.png',
          configured: false
        }
      ]
    },
    {
      title: "Security Applications",
      applications: [
        {
          name: "Firewalls",
          description: "Connect and manage firewall integrations",
          configured: false,
          products: [
            { name: "Palo Alto Networks", description: "Palo Alto Networks Firewall integration", configured: false },
            { name: "Cisco", description: "Cisco Firewall integration", configured: false },
            { name: "Fortinet", description: "Fortinet FortiGate integration", configured: false },
            { name: "Check Point", description: "Check Point Firewall integration", configured: false }
          ]
        },
        {
          name: "EDR",
          description: "Endpoint Detection and Response integration",
          configured: false,
          products: [
            { name: "Palo Alto Networks Cortex", description: "Cortex XDR integration", configured: false },
            { name: "CrowdStrike", description: "CrowdStrike Falcon integration", configured: false },
            { name: "SentinelOne", description: "SentinelOne integration", configured: false },
            { name: "Microsoft Defender", description: "Microsoft Defender for Endpoint integration", configured: false }
          ]
        },
        {
          name: "SIEM",
          description: "Security Information and Event Management integration",
          configured: false,
          products: [
            { name: "Splunk", description: "Splunk Enterprise Security integration", configured: false },
            { name: "IBM QRadar", description: "IBM QRadar integration", configured: false },
            { name: "Exabeam", description: "Exabeam Fusion SIEM integration", configured: false }
          ]
        },
        {
          name: "Cloud Security Services",
          description: "Cloud security service integrations",
          configured: false,
          products: [
            { name: "AWS Security Hub", description: "AWS Security Hub integration", configured: false },
            { name: "Azure Security Center", description: "Microsoft Azure Security Center integration", configured: false },
            { name: "GCP Security Command Center", description: "Google Cloud Security Command Center integration", configured: false }
          ]
        }
      ]
    }
  ];

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold tracking-tight">Integrators</h1>
      <div className="space-y-8">
        {categories.map((category) => (
          <div key={category.title} className="space-y-4">
            <h2 className="text-xl font-semibold">{category.title}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {category.applications.map((app) => (
                <Card
                  key={app.name}
                  className="relative flex flex-col hover:border-gray-300 dark:hover:border-gray-700 transition-colors"
                >
                  <div 
                    className={`flex-1 p-4 ${!app.products && 'hover:bg-gray-50 dark:hover:bg-gray-800/50 cursor-pointer'}`}
                    onClick={() => !app.products && handleSelectClick('communication', app.name)}
                  >
                    <div className="flex items-start justify-between">
                      <div className="flex items-center space-x-3">
                        {app.logoUrl && (
                          <img 
                            src={app.logoUrl} 
                            alt={`${app.name} logo`}
                            className="h-12 w-12 object-contain"
                          />
                        )}
                        {app.icon && <div>{app.icon}</div>}
                        <div>
                          <h3 className="font-medium">{app.name}</h3>
                          <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                            {app.description}
                          </p>
                        </div>
                      </div>
                      <div className="ml-4">
                        {app.products ? (
                          <button
                            onClick={() => toggleExpand(app.name)}
                            className="flex items-center px-3 py-1 rounded-full text-sm bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-gray-300"
                          >
                            <ChevronDown className={`h-4 w-4 transition-transform ${
                              expandedApps.includes(app.name) ? 'rotate-180' : ''
                            }`} />
                          </button>
                        ) : (
                          <div className={`px-3 py-1 rounded-full text-sm text-gray-900 dark:text-gray-100 ${
                            configs.some(c => c.integration_type === 'communication' && 
                                     c.integration_name === app.name && 
                                     c.is_configured)
                              ? 'bg-green-100 dark:bg-green-900 group-hover:bg-red-100 dark:group-hover:bg-red-900'
                              : 'bg-gray-100 dark:bg-gray-800'
                          }`}>
                            <span className="group-hover:hidden">
                              {configs.some(c => c.integration_type === 'communication' && 
                                       c.integration_name === app.name && 
                                       c.is_configured) 
                                ? 'Selected' 
                                : 'Select'}
                            </span>
                            <span className="hidden group-hover:inline">
                              {configs.some(c => c.integration_type === 'communication' && 
                                       c.integration_name === app.name && 
                                       c.is_configured) 
                                ? 'Edit/Remove' 
                                : 'Select'}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {app.products && expandedApps.includes(app.name) && (
                    <div className="absolute top-full left-0 right-0 z-10 bg-white dark:bg-gray-950 border border-gray-200 dark:border-gray-800 rounded-b-lg shadow-lg divide-y divide-gray-200 dark:divide-gray-800">
                      {app.products.map((product) => (
                        <div
                          key={product.name}
                          className="relative hover:bg-gray-50 dark:hover:bg-gray-800/50 transition-colors cursor-pointer"
                          onClick={() => handleSelectClick(app.name.toLowerCase(), product.name)}
                        >
                          <div className="p-4 flex items-center justify-between group/item">
                            <div className="flex items-center space-x-3">
                              {getApplicationLogo(product.name) && (
                                <img 
                                  src={getApplicationLogo(product.name)} 
                                  alt={`${product.name} logo`}
                                  className="h-12 w-12 object-contain"
                                />
                              )}
                              <div>
                                <h4 className="font-medium text-sm">{product.name}</h4>
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                  {product.description}
                                </p>
                              </div>
                            </div>
                            <div className={`px-3 py-1 rounded-full text-sm text-gray-900 dark:text-gray-100 ${
                              configs.some(c => c.integration_type === app.name.toLowerCase() && 
                                       c.integration_name === product.name && 
                                       c.is_configured)
                                ? 'bg-green-100 dark:bg-green-900 group-hover/item:bg-red-100 dark:group-hover/item:bg-red-900'
                                : 'bg-gray-100 dark:bg-gray-800'
                            }`}>
                              <span className="group-hover/item:hidden">
                                {configs.some(c => c.integration_type === app.name.toLowerCase() && 
                                         c.integration_name === product.name && 
                                         c.is_configured) 
                                  ? 'Selected' 
                                  : 'Select'}
                              </span>
                              <span className="hidden group-hover/item:inline">
                                {configs.some(c => c.integration_type === app.name.toLowerCase() && 
                                         c.integration_name === product.name && 
                                         c.is_configured) 
                                  ? 'Edit/Remove' 
                                  : 'Select'}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Card>
              ))}
            </div>
          </div>
        ))}
      </div>
      
      {selectedIntegration && (
        <IntegrationConfigModal
          key={`${selectedIntegration.type}-${selectedIntegration.name}`}
          isOpen={true}
          onClose={() => setSelectedIntegration(null)}
          integrationType={selectedIntegration.type}
          integrationName={selectedIntegration.name}
        />
      )}
    </div>
  );
};

export default IntegratorsPage; 