import React, { useState, useEffect } from 'react';
import { useDarkMode } from '../hooks/useDarkMode';
import { useUserDataStore } from '../stores/useUserDataStore';
import PasswordChange from '../components/PasswordChange';
import ProfileInformation from '../components/ProfileInformation';
import { Card } from '../ui-kit/card';

const MyAccountPage: React.FC = () => {
    const { userData, fetchUserData, error } = useUserDataStore();
    const { isDarkMode, toggleTheme } = useDarkMode();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [newUsername, setNewUsername] = useState<string>(userData.username);
    const [alertStatus, setAlertStatus] = useState<{ isSuccess: boolean; message: string } | null>(null);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    useEffect(() => {
        if (error) {
            setAlertStatus({
                isSuccess: false,
                message: 'Failed to load profile data. Please try again later.'
            });
        }
    }, [error]);

    useEffect(() => {
        setNewUsername(userData.username);
    }, [userData.username]);

    return (
        <div className="bg-background min-h-screen">
            <div className="max-w-7xl mx-auto p-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div>
                        <ProfileInformation
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            newUsername={newUsername}
                            setNewUsername={setNewUsername}
                            setAlertStatus={setAlertStatus}
                            userData={userData}
                        />
                        <Card className="p-6">
                            <div className="flex justify-between items-center">
                                <h2 className="text-xl font-medium">Theme Preferences</h2>
                                <button
                                    onClick={toggleTheme}
                                    className="px-4 py-2 bg-primary text-primary-foreground rounded hover:bg-primary/90"
                                >
                                    {isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
                                </button>
                            </div>
                        </Card>
                    </div>

                    <div>
                        <PasswordChange
                            onPasswordChangeSuccess={(message) => setAlertStatus({ isSuccess: true, message })}
                            onPasswordChangeError={(message) => setAlertStatus({ isSuccess: false, message })}
                        />
                    </div>
                </div>

                {alertStatus && (
                    <div className={`p-4 rounded mt-4 ${
                        alertStatus.isSuccess 
                            ? 'bg-success text-success-foreground' 
                            : 'bg-destructive text-destructive-foreground'
                    }`}>
                        {alertStatus.message}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MyAccountPage;