import React, { useState, useCallback, useEffect, useRef } from 'react';
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle,
  DialogDescription 
} from '../../ui-kit/dialog';
import { useIntegrationStore } from '../../stores/useIntegrationStore';
import { useAuth } from '../../context/AuthContext';
import { useSavedFilterStore } from '../../stores/useSavedFilterStore';

interface IntegrationConfigModalProps {
  isOpen: boolean;
  onClose: () => void;
  integrationType: string;
  integrationName: string;
}

interface ConfigFormData {
  api_url: string;
  api_key: string;
  api_user: string;
  additional_config?: {
    default_filter?: string;
  };
}

// Add new state for intervals
interface FilterInterval {
  days: number;
  hours: number;
  minutes: number;
}

export const IntegrationConfigModal = React.memo<IntegrationConfigModalProps>((props) => {
  const { isOpen, onClose, integrationType, integrationName } = props;
  const { isAuthenticated } = useAuth();
  const { configs, updateConfig, removeConfig } = useIntegrationStore();
  const { filters, fetchFilters } = useSavedFilterStore();
  const [formData, setFormData] = useState<ConfigFormData>({
    api_url: '',
    api_key: '',
    api_user: ''
  });
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const [filterIntervals, setFilterIntervals] = useState<Record<string, FilterInterval>>({});

  // Get the current config
  const currentConfig = configs.find(config => 
    config.integration_type === integrationType && 
    config.integration_name === integrationName
  );

  // Load existing config when modal opens
  useEffect(() => {
    if (isOpen && currentConfig) {
      setFormData({
        api_url: currentConfig.api_url || '',
        api_key: currentConfig.api_key || '',
        api_user: currentConfig.api_user || '',
      });
    }
  }, [isOpen, currentConfig]);

  // Convert minutes to days, hours, minutes
  const minutesToDHM = (totalMinutes: number): FilterInterval => {
    const days = Math.floor(totalMinutes / (24 * 60));
    const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
    const minutes = totalMinutes % 60;
    return { days, hours, minutes };
  };

  // Convert days, hours, minutes to total minutes
  const dhmToMinutes = (interval: FilterInterval): number => {
    return interval.days * 24 * 60 + interval.hours * 60 + interval.minutes;
  };

  // Load existing filters
  useEffect(() => {
    if (isOpen && currentConfig?.id) {
      const loadFilters = async () => {
        try {
          await fetchFilters();
          const integrationFilters = await useIntegrationStore.getState().getIntegrationFilters(currentConfig.id!);
          
          if (integrationFilters.length > 0) {
            const selectedFilterIds = integrationFilters.map(f => f.saved_filter_id);
            setSelectedFilters(selectedFilterIds);
            
            const intervals = integrationFilters.reduce((acc, filter) => {
              if (filter.run_interval_minutes !== undefined) {
                acc[filter.saved_filter_id] = minutesToDHM(filter.run_interval_minutes);
              }
              return acc;
            }, {} as Record<string, FilterInterval>);
            
            setFilterIntervals(intervals);
          }
        } catch (error) {
          console.error('Error loading filters:', error);
        }
      };
      
      loadFilters();
    } else {
      setSelectedFilters([]);
      setFilterIntervals({});
    }
  }, [isOpen, currentConfig, fetchFilters]);

  // Add default value handling for filterIntervals
  const getFilterInterval = (filterId: string): FilterInterval => {
    return filterIntervals[filterId] || { days: 0, hours: 1, minutes: 0 };
  };

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const updatedConfig = await updateConfig({
        integration_type: integrationType,
        integration_name: integrationName,
        ...formData,
        is_configured: true,
      });

      if (updatedConfig?.id) {
        // First, remove all existing filter assignments
        const existingFilters = await useIntegrationStore.getState().getIntegrationFilters(updatedConfig.id);
        for (const filter of existingFilters) {
          await useIntegrationStore.getState().removeFilterFromIntegration(
            updatedConfig.id,
            filter.saved_filter_id
          );
        }

        // Then add all selected filters with their current settings
        for (const filterId of selectedFilters) {
          const interval = getFilterInterval(filterId);
          const totalMinutes = dhmToMinutes(interval);
          await useIntegrationStore.getState().assignFilterToIntegration(
            updatedConfig.id,
            filterId,
            totalMinutes
          );
        }
      }

      onClose();
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      alert('Failed to update configuration: ' + (error as Error).message);
    }
  }, [formData, updateConfig, integrationType, integrationName, onClose, selectedFilters, filterIntervals]);

  const handleRemove = useCallback(async () => {
    try {
      await removeConfig(integrationType, integrationName);
      onClose();
    } catch (error) {
      console.error('Error in handleRemove:', error);
      alert('Failed to remove configuration: ' + (error as Error).message);
    }
  }, [removeConfig, integrationType, integrationName, onClose]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  }, []);

  if (!isAuthenticated || !isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent onOpenAutoFocus={() => initialFocusRef.current?.focus()}>
        <DialogHeader>
          <DialogTitle>Configure {integrationName}</DialogTitle>
          <DialogDescription>
            Enter your {integrationName} API credentials to configure the integration.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="api_url" className="block text-sm font-medium mb-1">API URL</label>
            <input
              ref={initialFocusRef}
              id="api_url"
              name="api_url"
              type="text"
              value={formData.api_url}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-800 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="https://api.example.com"
              required
            />
          </div>
          <div>
            <label htmlFor="api_key" className="block text-sm font-medium mb-1">API Key</label>
            <input
              id="api_key"
              name="api_key"
              type="password"
              value={formData.api_key}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-800 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="api_user" className="block text-sm font-medium mb-1">API User</label>
            <input
              id="api_user"
              name="api_user"
              type="text"
              value={formData.api_user}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-800 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-medium mb-1">
              Assigned Filters
            </label>
            {filters.length === 0 ? (
              <p className="text-sm text-muted-foreground">
                No saved filters available. Create filters in the Saved Filters section.
              </p>
            ) : (
              <div className="space-y-2 border rounded-md p-3">
                {filters.map((filter) => (
                  <div key={filter.id} className="flex items-center justify-between gap-2">
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        id={`filter-${filter.id}`}
                        checked={selectedFilters.includes(filter.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedFilters(prev => [...prev, filter.id]);
                            setFilterIntervals(prev => ({
                              ...prev,
                              [filter.id]: { days: 0, hours: 1, minutes: 0 } // Default to 1 hour
                            }));
                          } else {
                            setSelectedFilters(prev => prev.filter(id => id !== filter.id));
                            setFilterIntervals(prev => {
                              const newIntervals = { ...prev };
                              delete newIntervals[filter.id];
                              return newIntervals;
                            });
                          }
                        }}
                        className="h-4 w-4 rounded border-gray-300"
                      />
                      <label 
                        htmlFor={`filter-${filter.id}`}
                        className="text-sm cursor-pointer"
                      >
                        {filter.name}
                      </label>
                    </div>
                    {selectedFilters.includes(filter.id) && (
                      <div className="flex items-center gap-2">
                        <div className="flex items-center gap-1">
                          <input
                            type="number"
                            min="0"
                            value={getFilterInterval(filter.id).days}
                            onChange={(e) => {
                              const value = Math.max(0, parseInt(e.target.value) || 0);
                              setFilterIntervals(prev => ({
                                ...prev,
                                [filter.id]: { ...getFilterInterval(filter.id), days: value }
                              }));
                            }}
                            className="w-16 p-1 text-sm border rounded"
                          />
                          <span className="text-xs">days</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <input
                            type="number"
                            min="0"
                            max="23"
                            value={getFilterInterval(filter.id).hours}
                            onChange={(e) => {
                              const value = Math.max(0, Math.min(23, parseInt(e.target.value) || 0));
                              setFilterIntervals(prev => ({
                                ...prev,
                                [filter.id]: { ...getFilterInterval(filter.id), hours: value }
                              }));
                            }}
                            className="w-16 p-1 text-sm border rounded"
                          />
                          <span className="text-xs">hrs</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <input
                            type="number"
                            min="0"
                            max="59"
                            value={getFilterInterval(filter.id).minutes}
                            onChange={(e) => {
                              const value = Math.max(0, Math.min(59, parseInt(e.target.value) || 0));
                              setFilterIntervals(prev => ({
                                ...prev,
                                [filter.id]: { ...getFilterInterval(filter.id), minutes: value }
                              }));
                            }}
                            className="w-16 p-1 text-sm border rounded"
                          />
                          <span className="text-xs">min</span>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            <p className="text-xs text-muted-foreground">
              Select filters to assign to this integration. Mark one as default if desired.
            </p>
          </div>
          <div className="flex gap-2">
            <button
              type="submit"
              className="flex-1 bg-blue-600 text-white py-2 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Save Configuration
            </button>
            {configs.some(c => 
              c.integration_type === integrationType && 
              c.integration_name === integrationName
            ) && (
              <button
                type="button"
                onClick={handleRemove}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Remove
              </button>
            )}
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
});

IntegrationConfigModal.displayName = 'IntegrationConfigModal'; 