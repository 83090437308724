import React, { useState } from 'react';
import { updateUserPassword } from '../services/supabase';
import ReauthModal from '../components/ReauthModal';
import { Button } from "../ui-kit/button";
import { Card } from "../ui-kit/card";

interface PasswordChangeProps {
    onPasswordChangeSuccess: (message: string) => void;
    onPasswordChangeError: (message: string) => void;
}

const PasswordChange: React.FC<PasswordChangeProps> = ({ onPasswordChangeSuccess, onPasswordChangeError }) => {
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [isReauthModalOpen, setIsReauthModalOpen] = useState<boolean>(false);

    const handlePasswordChange = async () => {
        if (newPassword !== confirmPassword) {
            onPasswordChangeError('New passwords do not match');
            return;
        }
        setIsReauthModalOpen(true);
    };

    const onReauthSuccess = async () => {
        setIsReauthModalOpen(false);
        try {
            await updateUserPassword(newPassword);
            onPasswordChangeSuccess('Password changed successfully');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error: any) {
            console.error('Error changing password:', error);
            onPasswordChangeError(`Failed to change password: ${error.message}`);
        }
    };

    return (
        <Card className="p-6 mb-6">
            <h2 className="text-xl font-medium text-foreground mb-4">Change Password</h2>
            <input
                type="password"
                placeholder="Current Password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="block w-full border rounded-md p-2 mb-2 bg-background text-foreground dark:border-gray-600"
            />
            <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="block w-full border rounded-md p-2 mb-2 bg-background text-foreground dark:border-gray-600"
            />
            <input
                type="password"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="block w-full border rounded-md p-2 mb-2 bg-background text-foreground dark:border-gray-600"
            />
            <Button
                onClick={handlePasswordChange}
                disabled={!currentPassword || !newPassword || !confirmPassword}
                variant="default"
            >
                Change Password
            </Button>

            {isReauthModalOpen && (
                <ReauthModal
                    onClose={() => setIsReauthModalOpen(false)}
                    onReauthSuccess={onReauthSuccess}
                />
            )}
        </Card>
    );
};

export default PasswordChange;