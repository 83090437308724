import { create } from 'zustand'
import { AttributeCounts, ScalarAttribute, ArrayAttribute } from '../shared/types';
import { SCALAR_ATTRIBUTES, ARRAY_ATTRIBUTES, INDICATOR_ATTRIBUTES } from '../shared/constants';

import { 
  ArrayAttributeCountParams, 
  ScalarAttributeCountParams, 
  getScalarAttributeCount, 
  getArrayAttributeCount, 
  getIndicatorTypeCountFilter
} from '../services/pgService';

import { useDateRangeStore } from './useDateRangeStore';
import { useFilterStore } from './useFilterStore';

interface AttributeCountStore {
  fullCounts: AttributeCounts;
  filteredCounts: AttributeCounts;
  isLoading: boolean;
  isScalarAttributesLoading: boolean;
  isArrayAttributesLoading: boolean;
  isIndicatorCountsLoading: boolean;
  isInitialized: boolean;
  attributeOffsets: Record<string, number>;
  hasMoreAttributes: Record<string, boolean>;
  fetchAttributeCounts: () => Promise<void>;
  fetchMoreAttributeCounts: (attributeName: string) => Promise<void>;
}

const initialAttributeCounts: AttributeCounts = Object.fromEntries(
  [...SCALAR_ATTRIBUTES, ...ARRAY_ATTRIBUTES, ...INDICATOR_ATTRIBUTES].map(attr => [attr, {}])
);

const initialOffsets: Record<string, number> = Object.fromEntries(
  [...SCALAR_ATTRIBUTES, ...ARRAY_ATTRIBUTES, ...INDICATOR_ATTRIBUTES].map(attr => [attr, 0])
);

export const useAttributeCountStore = create<AttributeCountStore>((set, get) => ({
  fullCounts: initialAttributeCounts,
  filteredCounts: initialAttributeCounts,
  isLoading: false,
  isInitialized: false,
  isScalarAttributesLoading: false,
  isArrayAttributesLoading: false,
  isIndicatorCountsLoading: false,
  attributeOffsets: initialOffsets,
  hasMoreAttributes: {},

  fetchAttributeCounts: async () => {
    const { dateRange } = useDateRangeStore.getState();
    const { productFilter } = useFilterStore.getState();
    if (!dateRange?.from || !dateRange?.to) return;

    const { from, to } = dateRange;
    // Set isInitialized to true to prevent fetchAttributeCounts from being called again
    set({ isInitialized: true, isLoading: true, isScalarAttributesLoading: true });

    try {
      let newFilteredCounts = { ...get().filteredCounts };
      let newFullCounts = { ...get().fullCounts };
      const newHasMoreAttributes = {};

      // Fetch scalar attributes
      for (const attr of SCALAR_ATTRIBUTES) {      
        // Get filtered counts first
        const filteredCounts = await getScalarAttributeCount({ 
          attributeName: attr, 
          fromDate: from, 
          toDate: to,
          productFilter,
          limit: 10 
        } as ScalarAttributeCountParams);
        newFilteredCounts = { ...newFilteredCounts, ...filteredCounts };

        // Then get full counts only for the values we got from filtered query
        if (Object.keys(productFilter).length > 0) {
          const filteredValues = Object.keys(filteredCounts[attr] || {});
          const existingValues = Object.keys(newFullCounts[attr] || {});
          const newValues = filteredValues.filter(value => !existingValues.includes(value));
          
          if (newValues.length > 0) {
            const fullCounts = await getScalarAttributeCount({ 
              attributeName: attr, 
              fromDate: from, 
              toDate: to,
              specificValues: newValues
            } as ScalarAttributeCountParams);
            newFullCounts = { 
              ...newFullCounts,
              [attr]: {
                ...newFullCounts[attr],
                ...fullCounts[attr]
              }
            };
          }
        } else {
          // If no filter, use the same counts
          newFullCounts = { ...newFullCounts, ...filteredCounts };
        }

        newHasMoreAttributes[attr] = Object.keys(filteredCounts[attr] || {}).length >= 10;
      }

      set({ 
        isScalarAttributesLoading: false, 
        isArrayAttributesLoading: true,
        fullCounts: newFullCounts,
        filteredCounts: newFilteredCounts,
        hasMoreAttributes: newHasMoreAttributes,
      });

      // Fetch array attributes
      for (const attr of ARRAY_ATTRIBUTES) {       
        // Get filtered counts first
        const filteredCounts = await getArrayAttributeCount({ 
          entityType: attr, 
          fromDate: from, 
          toDate: to,
          productFilter,
          limit: 10 
        } as ArrayAttributeCountParams);
        newFilteredCounts = { ...newFilteredCounts, ...filteredCounts };

        // Then get full counts only for the values we got from filtered query
        if (Object.keys(productFilter).length > 0) {
          const filteredValues = Object.keys(filteredCounts[attr] || {});
          const existingValues = Object.keys(newFullCounts[attr] || {});
          const newValues = filteredValues.filter(value => !existingValues.includes(value));
          
          if (newValues.length > 0) {
            const fullCounts = await getArrayAttributeCount({ 
              entityType: attr, 
              fromDate: from, 
              toDate: to,
              specificValues: newValues
            } as ArrayAttributeCountParams);
            newFullCounts = { 
              ...newFullCounts,
              [attr]: {
                ...newFullCounts[attr],
                ...fullCounts[attr]
              }
            };
          }
        } else {
          // If no filter, use the same counts
          newFullCounts = { ...newFullCounts, ...filteredCounts };
        }

        newHasMoreAttributes[attr] = Object.keys(filteredCounts[attr] || {}).length >= 10;
      }

      set({ 
        isArrayAttributesLoading: false, 
        isIndicatorCountsLoading: true,
        fullCounts: newFullCounts,
        filteredCounts: newFilteredCounts,
        hasMoreAttributes: newHasMoreAttributes,
      });

      // Fetch indicator counts
      const filteredIndicatorCounts = await getIndicatorTypeCountFilter({ 
        fromDate: from, 
        toDate: to,
        productFilter
      });
      newFilteredCounts = { ...newFilteredCounts, ...filteredIndicatorCounts };

      if (Object.keys(productFilter).length > 0) {
        const fullIndicatorCounts = await getIndicatorTypeCountFilter({ 
          fromDate: from, 
          toDate: to
        });
        newFullCounts = { ...newFullCounts, ...fullIndicatorCounts };
      } else {
        newFullCounts = { ...newFullCounts, ...filteredIndicatorCounts };
      }

      set({ 
        fullCounts: newFullCounts,
        filteredCounts: newFilteredCounts,
        hasMoreAttributes: newHasMoreAttributes,
        isIndicatorCountsLoading: false,
        isLoading: false,
      });

    } catch (error) {
      console.error('Error fetching attribute counts:', error);
      set({ 
        isLoading: false, 
        isScalarAttributesLoading: false,
        isArrayAttributesLoading: false,
        isIndicatorCountsLoading: false
      });
    }
  },

  fetchMoreAttributeCounts: async (attributeName: string) => {
    const { dateRange } = useDateRangeStore.getState();
    const { productFilter } = useFilterStore.getState();
    if (!dateRange?.from || !dateRange?.to) return;

    const { from, to } = dateRange;
    const currentOffset = get().attributeOffsets[attributeName];
    const newOffset = currentOffset + 10;
    const PAGE_SIZE = 10;

    try {
      let filteredCounts: AttributeCounts;
      let fullCounts: AttributeCounts;

      if (SCALAR_ATTRIBUTES.includes(attributeName as ScalarAttribute)) {
        filteredCounts = await getScalarAttributeCount({
          attributeName: attributeName as ScalarAttribute,
          fromDate: from,
          toDate: to,
          productFilter,
          limit: PAGE_SIZE,
          offset: newOffset
        });

        if (Object.keys(productFilter).length > 0) {
          const specificValues = Object.keys(filteredCounts[attributeName] || {});
          fullCounts = await getScalarAttributeCount({
            attributeName: attributeName as ScalarAttribute,
            fromDate: from,
            toDate: to,
            specificValues
          });
        }

      } else if (ARRAY_ATTRIBUTES.includes(attributeName as ArrayAttribute)) {
        filteredCounts = await getArrayAttributeCount({
          entityType: attributeName as ArrayAttribute,
          fromDate: from,
          toDate: to,
          productFilter,
          limit: PAGE_SIZE,
          offset: newOffset
        });

        if (Object.keys(productFilter).length > 0) {
          const specificValues = Object.keys(filteredCounts[attributeName] || {});
          fullCounts = await getArrayAttributeCount({
            entityType: attributeName as ArrayAttribute,
            fromDate: from,
            toDate: to,
            specificValues
          });
        }
      }

      if (filteredCounts) {
        const newItemCount = Object.keys(filteredCounts[attributeName] || {}).length;
        
        set(state => ({
          filteredCounts: {
            ...state.filteredCounts,
            [attributeName]: {
              ...state.filteredCounts[attributeName],
              ...filteredCounts[attributeName]
            }
          },
          fullCounts: {
            ...state.fullCounts,
            [attributeName]: {
              ...state.fullCounts[attributeName],
              ...(fullCounts ? fullCounts[attributeName] : filteredCounts[attributeName])
            }
          },
          attributeOffsets: {
            ...state.attributeOffsets,
            [attributeName]: newOffset
          },
          hasMoreAttributes: {
            ...state.hasMoreAttributes,
            [attributeName]: newItemCount >= PAGE_SIZE
          }
        }));
      }
    } catch (error) {
      console.error(`Error fetching more ${attributeName} counts:`, error);
      // Set hasMoreAttributes to false on error
      set(state => ({
        hasMoreAttributes: {
          ...state.hasMoreAttributes,
          [attributeName]: false
        }
      }));
    }
  }
}));

// Subscribe to date range changes
useDateRangeStore.subscribe((state, prevState) => {
  if (!state.dateRange || !prevState.dateRange) return;
  
  if (state.dateRange.from.getTime() !== prevState.dateRange.from.getTime() ||
      state.dateRange.to.getTime() !== prevState.dateRange.to.getTime()) {
    useAttributeCountStore.getState().fetchAttributeCounts();
  }
});

// Subscribe to filter changes
useFilterStore.subscribe((state, prevState) => {
  if (state.productFilter !== prevState.productFilter) {
    useAttributeCountStore.getState().fetchAttributeCounts();
  }
});

