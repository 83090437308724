import { ApiSearchFilter } from '../shared/types';
import { apiKey, apiUrl } from '../shared/constants';

if (!apiKey || !apiUrl) {
  console.warn('Missing required environment variables');
}

const headers = {
  'Content-Type': 'application/json',
  'x-api-key': apiKey,
};

const API_URL = `${apiUrl}`;

export const pgQuery = async (queryText: string, values: any[]): Promise<any> => {
  try {
    const response = await fetch(API_URL + '/pg-query', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
      body: JSON.stringify({ queryText, values }),
      mode: 'cors'
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`API request failed with status ${response.status}: ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      console.error('This error often occurs due to CORS issues or network problems.');
      console.error('Please check your network connection and ensure the API endpoint is correct and accessible.');
    }
    throw error;
  }
}

export const searchProducts = async (filter: ApiSearchFilter): Promise<any> => {
  try {
    const response = await fetch(API_URL + '/product/search', {
      method: 'POST',
      headers,
      body: JSON.stringify(filter),
      mode: 'cors',
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`API request failed with status ${response.status}: ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      console.error('This error often occurs due to CORS issues or network problems.');
      console.error('Please check your network connection and ensure the API endpoint is correct and accessible.');
    }
    throw error;
  }
}

export const callAdminApi = async <T>(
  endpoint: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
  body?: any
): Promise<T> => {

  const DASHBOARD_API_KEY = process.env.REACT_APP_TRUSS_API_KEY;

  if (!DASHBOARD_API_KEY) {
    console.warn('Missing dashboard API key environment variable');
  }

  const dashboardHeaders = {
    'Content-Type': 'application/json',
    'x-api-key': DASHBOARD_API_KEY,
  };

  try {
    const response = await fetch(`${API_URL}${endpoint}`, {
      method,
      headers: dashboardHeaders,
      body: body ? JSON.stringify(body) : undefined,
      mode: 'cors',
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`API request failed with status ${response.status}: ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      console.error('This error often occurs due to CORS issues or network problems.');
      console.error('Please check your network connection and ensure the API endpoint is correct and accessible.');
    }
    throw error;
  }
};
