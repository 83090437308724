import { create } from 'zustand';

interface FeatureFlags {
  isFeatureEnabled: (feature: string) => boolean;
  features: Record<string, boolean>;
  setFeatures: (features: Record<string, boolean>) => void;
}

export const useFeatureFlags = create<FeatureFlags>((set) => ({
  features: {
    integrators: false,
    upgrade_to_user: true,
  },
  isFeatureEnabled: (feature: string) => {
    const state = useFeatureFlags.getState();
    return state.features[feature] ?? false;
  },
  setFeatures: (features) => set({ features }),
})); 