import { createClient } from '@supabase/supabase-js'

declare global {
  interface Window {
    ENV: {
      REACT_APP_SUPABASE_URL: string
      REACT_APP_SUPABASE_ANON_KEY: string
      REACT_APP_API_URL: string
      REACT_APP_TRUSS_API_KEY: string
    }
  }
}

// Try environment variables first, fall back to window.ENV
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || window.ENV?.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY || window.ENV?.REACT_APP_SUPABASE_ANON_KEY

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables')
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
})

// Add an empty export to ensure this is treated as a module
export {} 