import { ProductFilter, DateRange } from './types';
import { trussApi } from './constants';

export const toUTCDayBoundary = (date: Date | null, isEndOfDay: boolean): Date | null => {
  if (!date) return null;
  return isEndOfDay
    ? new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59, 999))
    : new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0));
}

export const genApiCode = (language: string, filter: ProductFilter, dateRange: DateRange, apiKey: string): string => {
  const baseUrl = trussApi + "/product/search";
  const params = {
    startdate: toUTCDayBoundary(dateRange.from, false)?.toISOString().slice(0, 16),
    enddate: toUTCDayBoundary(dateRange.to, true)?.toISOString().slice(0, 16),
    ...filter
  };

  const requestBody = JSON.stringify(params, null, 4);

  switch (language.toLowerCase()) {
    case 'curl':
      return `
curl -X 'POST' \\
  "${baseUrl}" \\
  -H "x-api-key: ${apiKey}" \\
  -H "Content-Type: application/json" \\
  -d '{ ${requestBody.slice(1, -1)}  }' | jq`;

    case 'javascript':
      return `
import requests

headers = {
    'x-api-key': '${apiKey}',
    'Content-Type': 'application/json'
}

response = requests.post('${baseUrl}', 
    headers = headers,
    json = {        ${requestBody.slice(1, -1).split('\n').map(line => '    ' + line).join('\n')}}
)`;

    case 'python':
      return `
import requests

headers = {
'x-api-key': '${apiKey}',
'Content-Type': 'application/json'
}

response = requests.post('${baseUrl}', 
headers=headers,
json={        ${requestBody.slice(1, -1).split('\n').map(line => '    ' + line).join('\n')}}
)

print(response.json())`;

    case 'bash':
      return `
data='{ ${requestBody.slice(1, -1)}  }'

response=$(curl -s -X POST "${baseUrl}" \\
-H "x-api-key: ${apiKey}" \\
-H "Content-Type: application/json" \\
-d "$data")

echo "$response" | jq`;

    case 'go':
      return `
package main

import (
"bytes"
"encoding/json"
"fmt"
"io/ioutil"
"net/http"
)

func main() {
data := ${requestBody}

jsonData, err := json.Marshal(data)
if err != nil {
    panic(err)
}

req, err := http.NewRequest("POST", "${baseUrl}", bytes.NewBuffer(jsonData))
if err != nil {
    panic(err)
}

req.Header.Set("x-api-key", "${apiKey}")
req.Header.Set("Content-Type", "application/json")

client := &http.Client{}
resp, err := client.Do(req)
if err != nil {
    panic(err)
}
defer resp.Body.Close()

body, _ := ioutil.ReadAll(resp.Body)
fmt.Println(string(body))
}`;

    case 'rust':
      return `
use reqwest::Client;
use serde_json::json;

#[tokio::main]
async fn main() -> Result<(), Box<dyn std::error::Error>> {
let client = Client::new();

let data = ${requestBody};

let res = client
    .post("${baseUrl}")
    .header("x-api-key", "${apiKey}")
    .header("Content-Type", "application/json")
    .json(&data)
    .send()
    .await?;

println!("{}", res.text().await?);
Ok(())
}`;

    case 'ruby':
      return `
require 'net/http'
require 'uri'
require 'json'

uri = URI.parse('${baseUrl}')
http = Net::HTTP.new(uri.host, uri.port)
http.use_ssl = true

request = Net::HTTP::Post.new(uri.path)
request['x-api-key'] = '${apiKey}'
request['Content-Type'] = 'application/json'
request.body = ${requestBody}

response = http.request(request)
puts JSON.parse(response.body)`;

    default:
      return `// Language '${language}' not supported yet`;
  }
}