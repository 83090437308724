import React, { useEffect, useState } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { supabaseClient } from '../services/supabase';

const PaymentSuccessPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [isVerified, setIsVerified] = useState(false);
    const [error, setError] = useState<string | null>(null);
    
    useEffect(() => {
        const verifyPayment = async () => {
            const sessionId = searchParams.get('session_id');

            if (!sessionId) {
                setError('No session ID found');
                return;
            }

            try {
                const response = await supabaseClient.functions.invoke('verify-payment', {
                    body: { 
                        sessionId
                    }
                });

                if (response.error) throw response.error;
                setIsVerified(true);
            } catch (err) {
                console.error('Verification error:', err);
                setError(err.message);
            }
        };

        verifyPayment();
    }, [searchParams]);

    if (error) {
        return (
            <div className="max-w-3xl mx-auto py-12 px-4">
                <h1 className="text-3xl font-bold mb-8">Payment Error</h1>
                <p className="text-red-600">{error}</p>
            </div>
        );
    }

    if (isVerified) {
        return <Navigate to="/billing" replace />;
    }

    return (
        <div className="max-w-3xl mx-auto py-12 px-4">
            <h1 className="text-3xl font-bold mb-8">Verifying Payment...</h1>
            <p>Please wait while we verify your payment.</p>
        </div>
    );
};

export default PaymentSuccessPage; 