import React, { useEffect } from 'react';
import ProductList from '../components/ProductList';
import { useAttributeCountStore } from '../stores/useAttributeCountStore';

const ProductsPage: React.FC = () => {
  const { fetchAttributeCounts, isInitialized } = useAttributeCountStore();

  useEffect(() => {
    if (!isInitialized) {
      fetchAttributeCounts();
    }
  }, [fetchAttributeCounts, isInitialized]);

  return (
    <div className="relative min-h-0 flex-1">
      <ProductList />
    </div>
  );
}

export default ProductsPage;
