import React, { FC, ReactElement } from "react";
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { Twitter, LinkedIn, YouTube, 
         MenuBook, Help, Code } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../ui-kit/card";

const ResourcesPage: FC = (): ReactElement => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      {/* Header Section */}
      <Typography variant="h3" component="h1" gutterBottom align="center" sx={{ mb: 6 }}>
        Resources & Documentation
      </Typography>

      {/* Main Resources Grid */}
      <Grid container spacing={4}>
        {/* Documentation Card */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader>
              <MenuBook sx={{ fontSize: 40, mb: 2, color: 'primary.main' }} />
              <CardTitle>Documentation</CardTitle>
              <CardDescription>
                Comprehensive guides and documentation to help you start working with Truss Security.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Link 
                href="https://truss-security.github.io/truss-docs/" 
                color="primary" 
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Documentation →
              </Link>
            </CardContent>
          </Card>
        </Grid>

        {/* API Reference Card */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader>
              <Code sx={{ fontSize: 40, mb: 2, color: 'primary.main' }} />
              <CardTitle>API Reference</CardTitle>
              <CardDescription>
                Detailed API documentation with examples for integrating Truss Security.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Link 
                href="https://truss-security.github.io/truss-docs/api" 
                color="primary" 
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
              >
                Explore API →
              </Link>
            </CardContent>
          </Card>
        </Grid>

        {/* Help Center Card */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader>
              <Help sx={{ fontSize: 40, mb: 2, color: 'primary.main' }} />
              <CardTitle>Help Center</CardTitle>
              <CardDescription>
                Get answers to common questions and learn how to make the most of Truss Security.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Link 
                href="https://truss-security.com/faqs" 
                color="primary" 
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit Help Center →
              </Link>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Community Section */}
      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Join Our Community
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Connect with the Truss community and stay current with the latest updates.
        </Typography>
        
        {/* Discord Feedback Card */}
        <Box sx={{ maxWidth: 600, mx: 'auto', mb: 4 }}>
          <Card>
            <CardHeader>
              <CardTitle>Share Your Feedback</CardTitle>
              <CardDescription>
                Join our Discord community to connect with the team, share your feedback, and help shape the future of Truss Security.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Link 
                href="https://discord.gg/truss-security" 
                color="primary" 
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ display: 'inline-flex', alignItems: 'center', gap: 1 }}
              >
                Join our Discord →
              </Link>
            </CardContent>
          </Card>
        </Box>
        
        <Box sx={{ mt: 3 }}>
          <Link 
            href="https://discord.gg/truss-security" 
            color="inherit" 
            sx={{ mx: 2 }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon 
              icon={faDiscord} 
              style={{ 
                fontSize: 35, 
                verticalAlign: 'middle',
                position: 'relative',
                top: '1px'
              }} 
            />
          </Link>
          <Link 
            href="https://twitter.com/truss_security" 
            color="inherit" 
            sx={{ mx: 2 }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter sx={{ fontSize: 40 }} />
          </Link>
          <Link 
            href="https://linkedin.com/company/truss_security" 
            color="inherit" 
            sx={{ mx: 2 }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedIn sx={{ fontSize: 40 }} />
          </Link>
          <Link 
            href="https://www.youtube.com/channel/UCjzO-8_mv3iKKf6baDWaYfw" 
            color="inherit" 
            sx={{ mx: 2 }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <YouTube sx={{ fontSize: 40 }} />
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default ResourcesPage;