import React, { useEffect, useState } from 'react';
import { useUserDataStore } from '../stores/useUserDataStore';
import { Button } from "../ui-kit/button";
import { useFeatureFlags } from '../stores/useFeatureFlags';
import { loadStripe } from '@stripe/stripe-js';
import { supabaseClient, fetchUserSubscription, type TrussSubscription } from '../services/supabase';
import { useSearchParams } from 'react-router-dom';

interface PlanFeature {
    name: string;
    included: boolean;
}

interface PricingPlan {
    name: string;
    price: number | null;
    interval: 'month' | 'year';
    features: PlanFeature[];
    buttonText?: string;
    isPopular?: boolean;
}

const plans: PricingPlan[] = [
    {
        name: 'Community',
        price: 0,
        interval: 'month',
        features: [
          { name: 'Dashboard Access', included: true },
          { name: 'Discord Community', included: true },
          { name: 'Security API Access', included: false },
          { name: 'Support Channel', included: false },
          { name: 'Commercial Use', included: false },
        ],
    },
    {
        name: 'User',
        price: 10,
        interval: 'month',
        features: [
          { name: 'Dashboard Access', included: true },
          { name: 'Discord Community', included: true },
          { name: 'Security API Access', included: true },
          { name: 'Support Channel', included: true },
          { name: 'Commercial Use', included: true },
        ],
        buttonText: 'Upgrade to User',
    },
];

// Initialize Stripe with your publishable key
const stripePromise = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY 
    ? loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
    : null;

const BillingPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const { userData, paymentStatus, setPaymentStatus } = useUserDataStore();
    const isUpgradeEnabled = useFeatureFlags((state) => state.isFeatureEnabled('upgrade_to_user'));
    const [isLoading, setIsLoading] = useState(false);
    const [subscription, setSubscription] = useState<TrussSubscription | null>(null);

    useEffect(() => {
        if (status === 'canceled') {
            setPaymentStatus('Payment was canceled. Please try again or contact support if you need assistance.');
        }
    }, [searchParams, setPaymentStatus]);

    useEffect(() => {
        if (userData.role === 'user') {
            fetchUserSubscription()
                .then(setSubscription)
                .catch(error => console.error('Error fetching subscription:', error));
        }
    }, [userData.role]);

    const handleUpgrade = async (planName: string) => {
        if (isUpgradeEnabled && stripePromise && planName === 'User') {
            try {
                // Create a Stripe checkout session
                const response = await supabaseClient.functions.invoke('create-checkout-session', {
                    method: 'POST'
                });
                console.log('Response xx:', response);

                if (response.error) {
                    throw response.error;
                }

                const { data } = response;
                if (!data?.sessionId) {
                    throw new Error('No session ID returned');
                }

                // Redirect to Stripe Checkout
                const stripe = await stripePromise;
                const { error: stripeError } = await stripe.redirectToCheckout({
                    sessionId: data.sessionId
                });

                if (stripeError) {
                    throw stripeError;
                }
            } catch (error) {
                console.error('Error:', error);
                // Fallback to email if payment fails
                window.location.href = 'mailto:admin@truss-security.com';
            }
        } else {
            // Fallback to email if feature flag is disabled
            window.location.href = 'mailto:admin@truss-security.com';
        }
    };

    const handleManageSubscription = async () => {
        setIsLoading(true);
        try {
            const response = await supabaseClient.functions.invoke('create-portal-session', {
                method: 'POST'
            });

            if (response.error) {
                console.error('Portal session error:', response.error);
                throw new Error(response.error);
            }

            const { data } = response;
            if (!data?.url) {
                throw new Error('No portal URL returned');
            }

            // Only redirect if we have a valid URL
            window.location.href = data.url;
        } catch (error) {
            console.error('Error creating portal session:', error);
            // Show an error message to the user instead of immediately redirecting
            setPaymentStatus(
                'Unable to access subscription management. Please try again or contact support if the problem persists.'
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-gray-50 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
            {/* Payment Status Message */}
            {paymentStatus && (
                <div className={`max-w-3xl mx-auto mb-8 p-4 rounded-lg ${
                    searchParams.get('status') === 'canceled' 
                        ? 'bg-yellow-50 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200' 
                        : 'bg-green-50 dark:bg-green-900 text-green-800 dark:text-green-200'
                }`}>
                    {paymentStatus}
                </div>
            )}

            {/* Header */}
            <div className="text-center mb-8">
                <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
                    Level Up Your Security
                </h1>
            </div>

            {/* Current Subscription Info */}
            <div className="mb-12 max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-lg shadow p-6">
                <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
                    Current Subscription
                </h2>
                <div className="space-y-4">
                    <div className="flex justify-between">
                        <span className="text-gray-600 dark:text-gray-300">Plan</span>
                        <span className="font-medium text-gray-900 dark:text-white">
                            {isLoading ? (
                                <span className="inline-block w-20 h-6 bg-gray-200 dark:bg-gray-700 animate-pulse rounded" />
                            ) : (
                                userData.role.charAt(0).toUpperCase() + userData.role.slice(1)
                            )}
                        </span>
                    </div>
                    <div className="flex justify-between">
                        <span className="text-gray-600 dark:text-gray-300">Billing Period</span>
                        <span className="font-medium text-gray-900 dark:text-white">
                            {isLoading ? (
                                <span className="inline-block w-16 h-6 bg-gray-200 dark:bg-gray-700 animate-pulse rounded" />
                            ) : (
                                userData.role === 'community' ? 'N/A' : 'Monthly'
                            )}
                        </span>
                    </div>
                    <div className="flex justify-between">
                        <span className="text-gray-600 dark:text-gray-300">Next Payment</span>
                        <span className="font-medium text-gray-900 dark:text-white">
                            {isLoading ? (
                                <span className="inline-block w-24 h-6 bg-gray-200 dark:bg-gray-700 animate-pulse rounded" />
                            ) : (
                                userData.role === 'community' 
                                    ? 'N/A' 
                                    : subscription?.current_period_end 
                                        ? new Date(subscription.current_period_end).toLocaleDateString() 
                                        : 'Loading...'
                            )}
                        </span>
                    </div>
                    
                    <div className="pt-4 flex justify-center">
                        <Button
                            onClick={userData.role === 'community' ? () => handleUpgrade('User') : handleManageSubscription}
                            size="sm"
                            disabled={userData.role === 'community' ? !isUpgradeEnabled : isLoading}
                        >
                            {userData.role === 'community' 
                                ? 'Upgrade to User' 
                                : isLoading ? 'Loading...' : 'Manage Subscription'}
                        </Button>
                    </div>
                </div>
            </div>

            {/* Pricing Cards */}
            <div className="max-w-7xl mx-auto grid gap-8 lg:grid-cols-2 justify-center">
                {plans.map((plan) => (
                    <div
                        key={plan.name}
                        className={`relative bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden
                            ${(userData.role === 'community' && plan.name === 'Community') || 
                              (userData.role === 'user' && plan.name === 'User') 
                                ? 'ring-2 ring-blue-500' 
                                : ''}`}
                    >
                        <div className="p-8">
                            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                                {plan.name}
                            </h2>
                            <p className="mt-4">
                                <span className="text-4xl font-extrabold text-gray-900 dark:text-white">
                                    ${plan.price}
                                </span>
                                <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                                    /{plan.interval}
                                </span>
                            </p>

                            {/* Features */}
                            <ul className="mt-8 space-y-4">
                                {plan.features.map((feature) => (
                                    <li
                                        key={feature.name}
                                        className="flex items-center"
                                    >
                                        {feature.included && !feature.name.includes('Coming Soon') && (
                                            <svg className="h-5 w-5 text-green-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                <path d="M5 13l4 4L19 7"></path>
                                            </svg>
                                        )}
                                        {!feature.included && (
                                            <svg className="h-5 w-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                <path d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                        )}
                                        <span className={`ml-3 ${feature.name.includes('Coming Soon')
                                                ? 'bg-yellow-100 dark:bg-yellow-900 px-2 py-1 rounded text-yellow-800 dark:text-yellow-200'
                                                : feature.included
                                                    ? 'text-gray-700 dark:text-gray-300'
                                                    : 'text-gray-500 dark:text-gray-400'
                                            }`}>
                                            {feature.name}
                                        </span>
                                    </li>
                                ))}
                            </ul>

                            {false && plan.buttonText && (
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => handleUpgrade(plan.name)}
                                    disabled={!isUpgradeEnabled && plan.name === 'User'}
                                    className="mt-8 w-full"
                                >
                                    {plan.buttonText}
                                </Button>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {/* Contribution Box */}
            <div className="max-w-4xl mx-auto mt-12 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
                <div className="text-center mb-6">
                    <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                        Would you like to contribute?
                    </h3>
                    <div className="grid grid-cols-3 gap-6 mb-8">
                        <div className="text-center">
                            <div className="text-blue-500 text-4xl mb-2">🤝</div>
                            <h4 className="font-semibold mb-2">Collaborate</h4>
                            <p className="text-sm text-gray-600 dark:text-gray-300">
                                Share your security insights
                            </p>
                        </div>
                        <div className="text-center">
                            <div className="text-blue-500 text-4xl mb-2">🚀</div>
                            <h4 className="font-semibold mb-2">Earn Rewards</h4>
                            <p className="text-sm text-gray-600 dark:text-gray-300">
                                Earn rewards for your contributions
                            </p>
                        </div>
                        <div className="text-center">
                            <div className="text-blue-500 text-4xl mb-2">🌎</div>
                            <h4 className="font-semibold mb-2">Global Protection</h4>
                            <p className="text-sm text-gray-600 dark:text-gray-300">
                                Strengthen global cybersecurity
                            </p>
                        </div>
                    </div>
                    <p className="text-gray-600 dark:text-gray-300 leading-relaxed mb-6">
                        Contribute to Truss Security as we build the future of collaborative security!
                        The Truss Security ecosystem enables rapid threat intelligence sharing, making the digital
                        world safer for everyone through collective defense.
                    </p>
                    <Button
                        onClick={() => handleUpgrade('User')}
                        variant="default"
                        size="lg"
                    >
                        Start the Conversation
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default BillingPage;