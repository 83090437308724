import React, { useState, useEffect } from 'react';
import { useFilterStore } from '../../stores/useFilterStore';
import { ProductFilter, ProductAttribute } from '../../shared/types';
import { Button } from '../../ui-kit/button';
import { Textarea } from '../../ui-kit/textarea';
import { Alert, AlertDescription } from '../../ui-kit/alert';
import { AlertCircle, RefreshCw } from 'lucide-react';
import jsep from 'jsep';
import { 
  SCALAR_ATTRIBUTES, 
  ARRAY_ATTRIBUTES, 
  INDICATOR_ATTRIBUTES 
} from '../../shared/constants';

const FilterQL: React.FC = () => {
  jsep.addBinaryOp("=", 10);
  jsep.addBinaryOp("OR", 3);
  jsep.addBinaryOp("AND", 2);

  const { productFilter, setProductFilter } = useFilterStore();
  const [expressionText, setExpressionText] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const expression = convertFilterToExpression();
    setExpressionText(expression);
    setError(null);
  }, [productFilter]);

  const convertFilterToExpression = () => {
    const parts = Object.entries(productFilter).map(([key, values]) => {
      if (values.length === 0) return null;
      if (values.length === 1) {
        return `${key}=${JSON.stringify(values[0])}`;
      }
      return `(${values.map(v => `${key}=${JSON.stringify(v)}`).join(' OR ')})`;
    }).filter(Boolean);

    return parts.join(' AND ');
  };

  const convertExpressionToFilter = (expression: string): ProductFilter | null => {
    try {
      if (!expression.trim()) {
        setError('Expression cannot be empty');
        return null;
      }

      const ast = jsep(expression);

      const newFilter: ProductFilter = {};

      const isValidAttribute = (name: string): name is ProductAttribute => {
        const allAttributes = [...SCALAR_ATTRIBUTES, ...ARRAY_ATTRIBUTES, ...INDICATOR_ATTRIBUTES];
        const normalizedName = name.toLowerCase();
        const normalizedAttributes = allAttributes.map(attr => attr.toLowerCase());

        return normalizedAttributes.includes(normalizedName);
      };

      const validateNode = (node: jsep.Expression): boolean => {
        if (!node) return false;

        switch (node.type) {
          case 'BinaryExpression': {
            const binaryNode = node as jsep.BinaryExpression;
            if (binaryNode.operator === '=') {
              const attributeName = (binaryNode.left as jsep.Identifier).name;
              if (!isValidAttribute(attributeName)) {
                throw new Error(`Invalid attribute name: "${attributeName}". Valid attributes are: ${[...SCALAR_ATTRIBUTES, ...ARRAY_ATTRIBUTES, ...INDICATOR_ATTRIBUTES].join(', ')}`);
              }
              // const correctAttribute = [...SCALAR_ATTRIBUTES, ...ARRAY_ATTRIBUTES, ...INDICATOR_ATTRIBUTES]
              //   .find(attr => attr.toLowerCase() === attributeName.toLowerCase()) as ProductAttribute;
              
              if (binaryNode.right.type !== 'Literal') {
                throw new Error('Right side of equality must be a value');
              }
              return true;
            } else if (['AND', 'OR'].includes(binaryNode.operator)) {
              return validateNode(binaryNode.left) && validateNode(binaryNode.right);
            }
            throw new Error(`Unsupported operator: ${binaryNode.operator}`);
          }
          default:
            throw new Error(`Unsupported expression type: ${node.type}`);
        }
      };

      if (!validateNode(ast)) {
        throw new Error('Invalid expression structure');
      }

      const processNode = (node: jsep.Expression, isOrContext = false) => {
        if (!node) return;

        switch (node.type) {
          case 'BinaryExpression': {
            const binaryNode = node as jsep.BinaryExpression;

            if (binaryNode.operator === '=') {
              const inputAttribute = (binaryNode.left as jsep.Identifier).name;
              const attribute = [...SCALAR_ATTRIBUTES, ...ARRAY_ATTRIBUTES, ...INDICATOR_ATTRIBUTES]
                .find(attr => attr.toLowerCase() === inputAttribute.toLowerCase());
              
              if (!attribute) {
                throw new Error(`Invalid attribute name: "${inputAttribute}". Valid attributes are: ${[...SCALAR_ATTRIBUTES, ...ARRAY_ATTRIBUTES, ...INDICATOR_ATTRIBUTES].join(', ')}`);
              }

              const value = (binaryNode.right as jsep.Literal).value;
              const stringValue = typeof value === 'string' ? value : String(value);

              newFilter[attribute] = newFilter[attribute] || [];
              if (!newFilter[attribute].includes(stringValue)) {
                newFilter[attribute].push(stringValue);
              }
            } else if (binaryNode.operator === 'AND') {
              processNode(binaryNode.left, false);
              processNode(binaryNode.right, false);
            } else if (binaryNode.operator === 'OR') {
              processNode(binaryNode.left, true);
              processNode(binaryNode.right, true);
            }
            break;
          }
          case 'UnaryExpression': {
            const unaryNode = node as jsep.UnaryExpression;
            processNode(unaryNode.argument, isOrContext);
            break;
          }
          default: {
            throw new Error(`Unsupported expression type: ${node.type}`);
          }
        }
      };

      processNode(ast);

      if (Object.keys(newFilter).length === 0) {
        throw new Error('No valid filters found in expression');
      }

      setError(null);
      return newFilter;
    } catch (error) {
      console.error('Expression error:', error);
      setError(error instanceof Error ? error.message : 'Invalid expression');
      return null;
    }
  };
  
  
  

  const handleExpressionChange = (expression: string) => {
    setExpressionText(expression);
  };

  const handleUpdateFilter = () => {
    if (expressionText.trim()) {
      const newFilter = convertExpressionToFilter(expressionText);
      if (newFilter !== null) {
        setProductFilter(newFilter);
      }
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <Textarea
        value={expressionText}
        onChange={(e) => handleExpressionChange(e.target.value)}
        placeholder='Example: color="red" AND (size="large" OR size="medium")'
        className="font-mono min-h-[100px]"
      />
      
      <div className="flex flex-col gap-2">
        <Button 
          onClick={handleUpdateFilter}
          className="w-fit"
          size="sm"
          variant="secondary"
        >
          <RefreshCw className="h-4 w-4 mr-2" />
          Update Filter
        </Button>

        {error && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              {error}
            </AlertDescription>
          </Alert>
        )}
      </div>
    </div>
  );
};

export default FilterQL; 