import { create } from 'zustand';
import { supabase } from '../lib/supabaseClient';

interface IntegrationConfig {
  id?: string;
  user_id: string;
  integration_type: string;
  integration_name: string;
  api_url?: string;
  api_key?: string;
  api_user?: string;
  additional_config?: Record<string, any>;
  is_configured: boolean;
}

interface IntegrationFilter {
  id: string;
  integration_config_id: string;
  saved_filter_id: string;
  run_interval_minutes: number;
  created_at: string;
  updated_at: string;
}

interface IntegrationStore {
  configs: IntegrationConfig[];
  isLoading: boolean;
  error: string | null;
  fetchConfigs: () => Promise<void>;
  updateConfig: (config: Omit<IntegrationConfig, 'user_id'>) => Promise<IntegrationConfig>;
  removeConfig: (integrationType: string, integrationName: string) => Promise<void>;
  assignFilterToIntegration: (integrationId: string, filterId: string, intervalMinutes: number) => Promise<void>;
  removeFilterFromIntegration: (integrationId: string, filterId: string) => Promise<void>;
  getIntegrationFilters: (integrationId: string) => Promise<IntegrationFilter[]>;
}

export const useIntegrationStore = create<IntegrationStore>((set, get) => ({
  configs: [],
  isLoading: false,
  error: null,

  fetchConfigs: async () => {
    set({ isLoading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      const { data, error } = await supabase
        .from('integration_configs')
        .select('*')
        .eq('user_id', user.id);

      if (error) throw error;
      set({ configs: data || [] });
    } catch (error) {
      console.error('Error in fetchConfigs:', error);
      set({ error: (error as Error).message });
    } finally {
      set({ isLoading: false });
    }
  },

  updateConfig: async (config: Omit<IntegrationConfig, 'user_id'>) => {
    set({ isLoading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      // First get the existing config
      const { data: existingConfig } = await supabase
        .from('integration_configs')
        .select('*')
        .match({ 
          user_id: user.id,
          integration_type: config.integration_type,
          integration_name: config.integration_name
        })
        .single();

      // Prepare the update data
      const updateData = {
        ...config,
        user_id: user.id,
        updated_at: new Date().toISOString()
      };

      // If we have an existing config, include its ID
      if (existingConfig) {
        updateData.id = existingConfig.id;
      }

      const { data, error } = await supabase
        .from('integration_configs')
        .upsert(updateData, {
          onConflict: 'user_id,integration_type,integration_name'
        })
        .select()
        .single();

      if (error) throw error;
      await get().fetchConfigs();
      return data as IntegrationConfig;
    } catch (error) {
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  removeConfig: async (integrationType, integrationName) => {
    set({ isLoading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      // First, verify the row exists
      const { data: existing } = await supabase
        .from('integration_configs')
        .select('*')
        .match({ 
          user_id: user.id,
          integration_type: integrationType, 
          integration_name: integrationName 
        });

      if (!existing?.length) {
        throw new Error('Configuration not found');
      }

      // Then attempt to delete
      const { error } = await supabase
        .from('integration_configs')
        .delete()
        .match({ 
          user_id: user.id,
          integration_type: integrationType, 
          integration_name: integrationName 
        });

      if (error) {
        console.error('Error removing config:', error);
        throw error;
      }

      // Verify deletion
      const { data: verifyDeletion } = await supabase
        .from('integration_configs')
        .select('*')
        .match({ 
          user_id: user.id,
          integration_type: integrationType, 
          integration_name: integrationName 
        });

      if (verifyDeletion?.length > 0) {
        throw new Error('Delete operation failed - row still exists');
      }

      console.log('Removed config successfully');
      await get().fetchConfigs();
    } catch (error) {
      console.error('Error in removeConfig:', error);
      set({ error: (error as Error).message });
      throw error; // Re-throw to handle in the component
    } finally {
      set({ isLoading: false });
    }
  },

  assignFilterToIntegration: async (integrationId, filterId, intervalMinutes) => {
    try {
      const { error } = await supabase
        .from('integration_filters')
        .upsert({
          integration_config_id: integrationId,
          saved_filter_id: filterId,
          run_interval_minutes: intervalMinutes,
          updated_at: new Date().toISOString()
        });

      if (error) throw error;
      console.log('Filter assignment successful');
    } catch (error) {
      console.error('Filter assignment failed:', error);
      throw error;
    }
  },

  removeFilterFromIntegration: async (integrationId, filterId) => {
    try {
      const { error } = await supabase
        .from('integration_filters')
        .delete()
        .match({ 
          integration_config_id: integrationId, 
          saved_filter_id: filterId 
        });

      if (error) throw error;
    } catch (error) {
      console.error('Error removing filter from integration:', error);
      throw error;
    }
  },

  getIntegrationFilters: async (integrationId) => {
    try {
      // First verify we can access the integration config
      const { error: configError } = await supabase
        .from('integration_configs')
        .select('id')
        .eq('id', integrationId)
        .single();
        
      if (configError) {
        console.error('Error accessing integration config:', configError);
        throw configError;
      }

      // Then fetch the filters
      const { data, error } = await supabase
        .from('integration_filters')
        .select(`
          id,
          integration_config_id,
          saved_filter_id,
          run_interval_minutes,
          created_at,
          updated_at
        `)
        .eq('integration_config_id', integrationId);

      if (error) {
        console.error('Error fetching filters:', error);
        throw error;
      }
      
      const filters = data || [];
      return filters;
    } catch (error) {
      console.error('Error in getIntegrationFilters:', error);
      throw error;
    }
  }
})); 