import React, { useEffect } from 'react';
import ApiCodeSamples from '../components/ApiCodeSamples';
import { useAttributeCountStore } from '../stores/useAttributeCountStore';

const ApiPage: React.FC = () => {
  const { fetchAttributeCounts, isInitialized } = useAttributeCountStore();

  useEffect(() => {
    if (!isInitialized) {
      fetchAttributeCounts();
    }
  }, [fetchAttributeCounts, isInitialized]);

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full">
        <ApiCodeSamples />
      </div>
    </div>
  );
}

export default ApiPage;
