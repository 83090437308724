import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { signUpUser, sendPasswordResetEmail } from '../services/supabase';
import { Input } from '../ui-kit/input';

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const { login, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [isForgotPassword, setIsForgotPassword] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/insights');
        }
    }, [isAuthenticated, navigate]);

    const validateInputs = () => {
        if (!email || !password) {
            setError('Email and password are required.');
            return false;
        }
        
        if (isRegistering) {
            // Password requirements
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if (!passwordRegex.test(password)) {
                setError('Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.');
                return false;
            }
            
            if (password !== confirmPassword) {
                setError('Passwords do not match.');
                return false;
            }
        }
        return true;
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateInputs()) return;

        setIsLoading(true);
        setError('');
        try {
            await login(email, password);
            navigate('/insights');
        } catch (err: any) {
            console.error('Login failed:', err);
            setError(err.message || 'Login failed. Please check your email and password.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSignUp = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateInputs()) return;

        setIsLoading(true);
        setError('');
        setMessage('');
        
        try {
            const response = await signUpUser(email, password);
            if (response.message) {
                setMessage(response.message);
            } else {
                setMessage('Check your email for the confirmation link!');
                setEmail('');
                setPassword('');
            }
        } catch (err: any) {
            console.error('Sign up failed:', err);
            setError(err.message || 'Sign up failed. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleForgotPassword = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!email) {
            setError('Please enter your email address.');
            return;
        }

        setIsLoading(true);
        setError('');
        setMessage('');

        try {
            await sendPasswordResetEmail(email);
            setMessage('Password reset link has been sent to your email!');
            setIsForgotPassword(false);
        } catch (err: any) {
            console.error('Password reset failed:', err);
            setError(err.message || 'Failed to send reset email. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const validatePassword = (password: string) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        if (isRegistering) {
            setIsPasswordValid(validatePassword(newPassword));
        }
    };

    return (
        <div className="flex min-h-screen p-8 md:p-12 lg:p-16 bg-background">
            {/* Left Panel */}
            <div className="hidden lg:flex lg:w-1/2 bg-card items-center justify-center p-12 rounded-l-2xl shadow-lg">
                <div className="max-w-lg">
                    <img
                        src={`${process.env.PUBLIC_URL}/Truss_Logo.png`}
                        alt="Truss Logo"
                        className="w-full h-auto"
                    />
                </div>
            </div>

            {/* Right Panel - Login Form */}
            <div className="w-full lg:w-1/2 flex items-center justify-center bg-card p-8 rounded-2xl lg:rounded-l-none shadow-lg">
                <div className="w-full max-w-md space-y-8">
                    {/* Show logo only on mobile */}
                    <div className="lg:hidden text-center mb-8">
                        <img
                            src={`${process.env.PUBLIC_URL}/Truss_Logo.png`}
                            alt="Truss Logo"
                            className="mx-auto h-16 w-auto"
                        />
                    </div>

                    <div className="text-center">
                        <h2 className="text-2xl font-semibold text-foreground">
                            {isRegistering ? 'Create your account' : 'Welcome back'}
                        </h2>
                        <p className="mt-2 text-sm text-muted-foreground">
                            {isRegistering 
                                ? 'Sign up to get started' 
                                : 'Please sign in to your account'}
                        </p>
                    </div>

                    {message && (
                        <div className="p-3 text-sm bg-emerald-50 dark:bg-emerald-950 text-emerald-700 dark:text-emerald-300 rounded-md">
                            {message}
                        </div>
                    )}

                    <form 
                        onSubmit={
                            isForgotPassword 
                                ? handleForgotPassword 
                                : (isRegistering ? handleSignUp : handleLogin)
                        } 
                        className="space-y-4"
                    >
                        <div className="space-y-2">
                            <label className="text-sm font-medium text-foreground">Email</label>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        {!isForgotPassword && (
                            <>
                                <div className="space-y-2">
                                    <label className="text-sm font-medium text-foreground">Password</label>
                                    <Input
                                        type="password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        required
                                        className={isRegistering && !isPasswordValid && password ? 'border-destructive' : ''}
                                    />
                                    {isRegistering && (
                                        <p className={`text-xs ${!isPasswordValid && password ? 'text-destructive' : 'text-muted-foreground'} mt-1`}>
                                            Password must be at least 8 characters long and contain at least one uppercase letter, 
                                            one lowercase letter, one number, and one special character.
                                        </p>
                                    )}
                                </div>
                                {isRegistering && (
                                    <div className="space-y-2">
                                        <label className="text-sm font-medium text-foreground">Confirm Password</label>
                                        <Input
                                            type="password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                )}
                            </>
                        )}
                        {!isRegistering && !isForgotPassword && (
                            <button
                                type="button"
                                onClick={() => {
                                    setIsForgotPassword(!isForgotPassword);
                                    setError('');
                                    setMessage('');
                                }}
                                className="text-sm text-muted-foreground hover:text-foreground"
                            >
                                Forgot your password?
                            </button>
                        )}
                        {error && <p className="text-destructive text-sm">{error}</p>}
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={`w-full py-2 px-4 rounded-md text-primary-foreground transition-colors ${
                                isLoading ? 'bg-muted cursor-not-allowed' : 'bg-primary hover:bg-primary/90'
                            }`}
                        >
                            {isLoading 
                                ? (isForgotPassword ? 'Sending...' : (isRegistering ? 'Signing up...' : 'Signing in...'))
                                : (isForgotPassword ? 'Send Reset Link' : (isRegistering ? 'Sign Up' : 'Sign in'))}
                        </button>
                        
                        <div className="text-center pt-2">
                            <span className="text-sm text-muted-foreground">
                                {isRegistering ? 'Already have an account? ' : 'Need an account? '}
                            </span>
                            <button
                                type="button"
                                onClick={() => {
                                    setIsRegistering(!isRegistering);
                                    setIsForgotPassword(false);
                                    setError('');
                                    setMessage('');
                                }}
                                className="text-sm text-primary hover:text-primary/80"
                            >
                                {isRegistering ? 'Sign in' : 'Sign up'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;