import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactECharts from 'echarts-for-react';
import { generateCustomPaletteColors } from '../../shared/colorUtils';
import { useFilterStore } from '../../stores/useFilterStore';
import { Card } from '../../ui-kit/card';
import { useDarkMode } from '../../hooks/useDarkMode';

interface MultiLineChartProps {
    title: string;
    data: any[];
    xKey: string;
    yKeys: string[];
    itemKey: string;
}

const MultiLineChart: React.FC<MultiLineChartProps> = ({ title, data, xKey, yKeys, itemKey }) => {
    const navigate = useNavigate();
    const { isDarkMode } = useDarkMode();
    const { addFilter } = useFilterStore();
    const [visibleKeys, setVisibleKeys] = useState<string[]>(
        yKeys.filter(key => key !== 'All')
    );

    // Filter out empty keys and sort
    const sortedKeys = [...yKeys]
        .filter(key => key && key.trim() !== '')
        .sort((a, b) => {
            const totalA = data.reduce((sum, item) => sum + (item[a] || 0), 0);
            const totalB = data.reduce((sum, item) => sum + (item[b] || 0), 0);
            return totalB - totalA;
        });

    const colors = generateCustomPaletteColors(sortedKeys.length);

    const chartOptions = {
        backgroundColor: 'transparent',
        title: {
            text: title,
            left: '40%',
            top: 12,
            show: true,
            textStyle: { 
                fontSize: 18,
                color: isDarkMode ? '#FFFFFF' : '#000000',
                fontWeight: 600
            },
        },
        tooltip: {
            show: true,
            trigger: 'item',
            enterable: true,
            position: (point: number[]) => [point[0] + 10, point[1] + 10],
            formatter: (params: any) => {
                if (params.componentType === 'series') {
                    const currentValue = params.value;
                    const currentIndex = params.dataIndex;
                    const matchingSeries = sortedKeys.filter(key => {
                        const value = data[currentIndex][key];
                        return value === currentValue && value !== 0;
                    });

                    if (matchingSeries.length > 1) {
                        const tooltipItems = matchingSeries
                            .map(name => {
                                const escapedItemName = name.replace(/'/g, "\\'");
                                return `<div class="tooltip-item" 
                                    style="padding: 4px; cursor: pointer;" 
                                    onclick="document.dispatchEvent(new CustomEvent('tooltipDoubleClick', {detail: {name: '${escapedItemName}', type: '${itemKey}'}}))">
                                    ${name}: ${currentValue}
                                </div>`;
                            })
                            .join('');
                        return tooltipItems;
                    } else {
                        const escapedName = params.seriesName.replace(/'/g, "\\'");
                        return `<div class="tooltip-item" 
                            style="padding: 4px; cursor: pointer;" 
                            onclick="document.dispatchEvent(new CustomEvent('tooltipDoubleClick', {detail: {name: '${escapedName}', type: '${itemKey}'}}))">
                            ${params.seriesName}: ${currentValue}
                        </div>`;
                    }
                }
                return '';
            },
            backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)',
            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',
            textStyle: {
                color: isDarkMode ? '#FFFFFF' : '#000000'
            },
            extraCssText: `
                backdrop-filter: blur(8px); 
                -webkit-backdrop-filter: blur(8px); 
                border-radius: 4px; 
                box-shadow: 0 4px 6px ${isDarkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.1)'};
            `
        },
        legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 10,
            top: 'middle',
            itemWidth: 14,
            itemHeight: 14,
            textStyle: { 
                fontSize: 12,
                color: isDarkMode ? '#FFFFFF' : '#000000'
            },
            formatter: (name: string) => {
                const maxLength = 20;
                return name.length > maxLength ? name.slice(0, maxLength) + '...' : name;
            },
            selectedMode: true,
            data: sortedKeys,
            selected: sortedKeys.reduce((acc, key) => ({
                ...acc,
                [key]: visibleKeys.includes(key)
            }), {})
        },
        grid: {
            left: '4%',
            right: '20%',
            bottom: '4%',
            top: '64px',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            data: data.map(item => item[xKey]),
            axisLabel: {
                formatter: (value: string) => {
                    const date = new Date(value);
                    return `${date.getMonth() + 1}-${date.getDate()}`;
                },
                color: isDarkMode ? '#FFFFFF' : '#000000'
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
                }
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                color: isDarkMode ? '#FFFFFF' : '#000000',
                formatter: (value: number) => value.toLocaleString()
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                    opacity: 0.3
                }
            }
        },
        series: sortedKeys.slice(0, 20).map((key, index) => ({
            name: key,
            type: 'line',
            data: data.map(item => item[key] || 0),
            smooth: true,
            itemStyle: {
                color: colors[index % colors.length]
            },
            symbol: 'circle',
            symbolSize: 8,
            cursor: 'pointer',
            emphasis: {
                scale: true,
                focus: 'series',
                lineStyle: {
                    width: 3
                },
                itemStyle: {
                    borderWidth: 2,
                    borderColor: colors[index % colors.length],
                    shadowBlur: 10,
                    shadowColor: 'rgba(0, 0, 0, 0.2)'
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: (params: any) => {
                        const currentValue = params.value;
                        const currentIndex = params.dataIndex;
                        const matchingSeries = sortedKeys.filter(key => {
                            const value = data[currentIndex][key];
                            return value === currentValue && value !== 0;
                        });

                        if (matchingSeries.length === 1) {
                            return `${params.seriesName}\n${params.value}`;
                        }
                        return params.value;
                    },
                    fontSize: 12,
                    color: isDarkMode ? '#FFFFFF' : '#000000',
                    padding: [2, 4],
                    distance: 10,
                    backgroundColor: 'transparent'
                }
            },
            select: {
                itemStyle: {
                    width: 3
                },
                label: {
                    show: true,
                    color: isDarkMode ? '#FFFFFF' : '#000000',
                    position: 'top',
                    formatter: '{c}',
                    fontSize: 12,
                    padding: [2, 4],
                    distance: 10,
                    backgroundColor: 'transparent'
                }
            },
            focus: 'series',
            blur: {
                itemStyle: {
                    opacity: 0.2
                },
                label: {
                    show: false
                }
            },
            triggerLineEvent: true,
            tooltip: {
                trigger: 'item'
            },
            hoverAnimation: true,
            legendHoverLink: true,
            sampling: 'average',
            connectNulls: true,
            animationDuration: 200,
            silent: false,
            snap: true,
            showSymbol: true,
            showAllSymbol: 'auto',
            triggerEvent: true
        }))
    };

    const onEvents = {
        'legendselectchanged': (params: any) => {
            const newVisibleKeys = Object.entries(params.selected)
                .filter(([_, isSelected]) => isSelected)
                .map(([key]) => key);
            setVisibleKeys(newVisibleKeys);
        },
        'dblclick': (params: any) => {
            if (params && params.seriesName) {
                addFilter(itemKey as any, params.seriesName);
                navigate('/products');
            }
        }
    };

    React.useEffect(() => {
        const handler = (e: CustomEvent) => {
            const { name, type } = e.detail;
            if (type === itemKey) {  // Only add filter if it matches this chart's type
                addFilter(type as any, name);
                navigate('/products');
            }
        };
        document.addEventListener('tooltipDoubleClick', handler as EventListener);
        return () => document.removeEventListener('tooltipDoubleClick', handler as EventListener);
    }, [itemKey]);

    return (
        <Card className="p-4">
            <ReactECharts
                option={chartOptions}
                style={{ height: 500, width: '100%' }}
                onEvents={onEvents}
                theme={isDarkMode ? 'dark' : undefined}
            />
        </Card>
    );
};

export default MultiLineChart;