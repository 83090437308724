import React, { useState } from 'react';
import { Button } from '../../ui-kit/button';
import { useFilterStore } from '../../stores/useFilterStore';
import FilterBasic from './FilterBasic';
import FilterQL from './FilterQL';
import { cn } from '../../lib/utils';
import { FilterIcon, Code2Icon, XIcon } from 'lucide-react';
import { SavedFiltersMenu } from './SavedFiltersMenu';
import { Card } from '../../ui-kit/card';

const FilterPanel: React.FC = () => {
  const { setProductFilter } = useFilterStore();
  const [viewMode, setViewMode] = useState<'basic' | 'ql'>('basic');

  return (
    <Card className="filter-panel p-4">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <h3 className="text-lg font-semibold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
              Filters
            </h3>
          </div>
          <div className="inline-flex rounded-md shadow-sm">
            <Button
              variant="outline"
              size="sm"
              className={cn(
                "rounded-r-none border-r-0 hover:bg-accent/50 transition-all duration-200",
                viewMode === 'basic' && "bg-accent/80 text-accent-foreground border-accent"
              )}
              onClick={() => setViewMode('basic')}
            >
              <FilterIcon className="w-4 h-4 mr-2" />
              Basic
            </Button>
            <Button
              variant="outline"
              size="sm"
              className={cn(
                "rounded-l-none hover:bg-accent/50 transition-all duration-200",
                viewMode === 'ql' && "bg-accent/80 text-accent-foreground border-accent"
              )}
              onClick={() => setViewMode('ql')}
            >
              <Code2Icon className="w-4 h-4 mr-2" />
              QL
            </Button>
          </div>
        </div>

        <div className="flex items-center">
          <SavedFiltersMenu />
          <Button
            variant="ghost"
            size="sm"
            className="hover:bg-destructive/10 hover:text-destructive transition-colors"
            onClick={() => setProductFilter({})}
          >
            <XIcon className="w-3 h-4 mr-1" />
            Clear
          </Button>
        </div>
      </div>

      <div className="relative">
        {viewMode === 'basic' ? <FilterBasic /> : <FilterQL />}
      </div>
    </Card>
  );
};

export default FilterPanel;
