import React, { useEffect, useState } from 'react';
import { Button } from '../../ui-kit/button';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../ui-kit/dropdown-menu';
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle,
  DialogDescription,
} from '../../ui-kit/dialog';
import { useFilterStore } from '../../stores/useFilterStore';
import { useDateRangeStore } from '../../stores/useDateRangeStore';
import { useSavedFilterStore, SavedFilter } from '../../stores/useSavedFilterStore';
import { Save, FolderOpen, Trash2 } from 'lucide-react';

export const SavedFiltersMenu: React.FC = () => {
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [filterToDelete, setFilterToDelete] = useState<SavedFilter | null>(null);
  const [filterName, setFilterName] = useState('');
  const [filterDescription, setFilterDescription] = useState('');
  const { productFilter } = useFilterStore();
  const { dateRange } = useDateRangeStore();
  const { filters, fetchFilters, saveFilter, deleteFilter } = useSavedFilterStore();

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  const handleSaveFilter = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await saveFilter(filterName, filterDescription, {
        productFilter: productFilter,
        dateRange: {
          from: dateRange.from.toISOString(),
          to: dateRange.to.toISOString(),
        },
      });
      setSaveDialogOpen(false);
      setFilterName('');
      setFilterDescription('');
    } catch (error) {
      console.error('Failed to save filter:', error);
    }
  };

  const handleLoadFilter = (filter: SavedFilter) => {
    // Handle both old and new filter_config formats
    const productFilter = 'productFilter' in filter.filter_config 
      ? filter.filter_config.productFilter
      : filter.filter_config;

    useFilterStore.setState({ productFilter });
  };

  const handleDeleteClick = (e: React.MouseEvent, filter: SavedFilter) => {
    e.stopPropagation();
    setFilterToDelete(filter);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (filterToDelete) {
      try {
        await deleteFilter(filterToDelete.id);
        setDeleteDialogOpen(false);
        setFilterToDelete(null);
        await fetchFilters();
      } catch (error) {
        console.error('Failed to delete filter:', error);
      }
    }
  };

  return (
    <div className="flex items-center gap-2">
      <Button variant="outline" size="sm" onClick={() => setSaveDialogOpen(true)}>
        <Save className="h-4 w-4 mr-2" />
        Save Filter
      </Button>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size="sm">
            <FolderOpen className="h-4 w-4 mr-2" />
            Load Filter
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-56">
          {filters.length === 0 ? (
            <DropdownMenuItem disabled>No saved filters</DropdownMenuItem>
          ) : (
            filters.map((filter) => (
              <DropdownMenuItem
                key={filter.id}
                className="flex items-center justify-between"
                onClick={() => handleLoadFilter(filter)}
              >
                <span>{filter.name}</span>
                <Trash2
                  className="h-4 w-4 text-red-500 hover:text-red-700 cursor-pointer"
                  onClick={(e) => handleDeleteClick(e, filter)}
                />
              </DropdownMenuItem>
            ))
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      {/* Save Filter Dialog */}
      <Dialog open={saveDialogOpen} onOpenChange={setSaveDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Save Current Filter</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSaveFilter} className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Filter Name</label>
              <input
                type="text"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Description (optional)</label>
              <textarea
                value={filterDescription}
                onChange={(e) => setFilterDescription(e.target.value)}
                className="w-full p-2 border rounded"
                rows={3}
              />
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <Button type="button" variant="outline" onClick={() => setSaveDialogOpen(false)}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Filter</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete the filter &apos;{filterToDelete?.name}&apos;? 
              This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-end gap-2 mt-4">
            <Button
              variant="outline"
              onClick={() => {
                setDeleteDialogOpen(false);
                setFilterToDelete(null);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={handleConfirmDelete}
            >
              Delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}; 