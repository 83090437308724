import React, { useEffect } from 'react';
import { useAttributeCountStore } from '../stores/useAttributeCountStore';
import ChartsPanel from '../components/ChartsPanel';

const ChartsPage: React.FC = () => {
    const { fetchAttributeCounts, filteredCounts, isInitialized } = useAttributeCountStore();

    useEffect(() => {
        if (!isInitialized) {
            fetchAttributeCounts();
        }
    }, [fetchAttributeCounts, isInitialized]);

    return (
        <div className="flex flex-col bg-background">
            <div className="w-full">
                <ChartsPanel attributeCounts={filteredCounts} />
            </div>
        </div>
    );
};

export default ChartsPage;