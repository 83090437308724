import * as React from "react";
import { BarChart3, Database, ChevronsUpDown, User2, LayoutDashboard, ChevronDown, LogOut, BadgeCheck, Settings, MessageCircleCode, CreditCard, Puzzle, Filter } from "lucide-react";
import Logo from '../images/Truss_Logo.png';
import { 
  Sidebar, 
  SidebarContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel
} from "../ui-kit/sidebar";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "../ui-kit/dropdown-menu";
import AppSidebarFilter from './AppSidebarFilter';
import { DatePickerWithRange } from './DatePickerWithRange';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../ui-kit/collapsible";
import { Link, useLocation } from 'react-router-dom';
import { useUserDataStore } from "../stores/useUserDataStore";
import { useFeatureFlags } from "../stores/useFeatureFlags";

export function AppSidebar() {
  const location = useLocation();
  const { userData, fetchUserData } = useUserDataStore();
  const { isFeatureEnabled } = useFeatureFlags();
  const showFilters = ['/charts', '/products', '/api'].includes(location.pathname);

  React.useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <>
      <Sidebar>
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton asChild>
                <Link to="/insights">
                  <img src={Logo} alt="Truss Logo" className="w-7 h-6 mr-2" />
                  Truss
                  <span className="ml-1.5 px-1.5 py-0.5 text-xs font-medium rounded-md bg-blue-100 text-blue-800">Beta</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>

        <SidebarContent>
          <div className="space-y-1">
            <SidebarMenuButton tooltip="Insights" asChild>
              <Link to="/insights">
                <LayoutDashboard />
                <span className="text-base">Insights</span>
              </Link>
            </SidebarMenuButton>
            <SidebarMenuButton tooltip="Query Builder" asChild>
              <Link to="/products">
                <Database />
                <span className="text-base">Products</span>
              </Link>
            </SidebarMenuButton>
            <SidebarMenuButton tooltip="Charts" asChild>
              <Link to="/charts">
                <BarChart3 />
                <span className="text-base">Charts</span>
              </Link>
            </SidebarMenuButton>
            <SidebarMenuButton tooltip="API" asChild>
              <Link to="/api">
                <MessageCircleCode />
                <span className="text-base">API</span>
              </Link>
            </SidebarMenuButton>
          </div>
          {showFilters && (
            <>
              <DatePickerWithRange />
              <Collapsible defaultOpen className="group/collapsible mt-1">
                <SidebarGroup>
                  <SidebarGroupLabel asChild>
                    <CollapsibleTrigger>
                      Filter
                      <ChevronDown className="ml-auto transition-transform group-data-[state=closed]/collapsible:-rotate-90" />
                    </CollapsibleTrigger>
                  </SidebarGroupLabel>
                  <CollapsibleContent>
                    <SidebarGroupContent>
                      <AppSidebarFilter />
                    </SidebarGroupContent>
                  </CollapsibleContent>
                </SidebarGroup>
              </Collapsible>
            </>
          )}
        </SidebarContent>

        <SidebarFooter>
          <SidebarMenu>
            <SidebarMenuItem>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <SidebarMenuButton>
                      <User2 /> {userData.username || 'Guest'}
                      <ChevronsUpDown className="ml-auto" />
                    </SidebarMenuButton>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    side="top"
                    className="w-[--radix-popper-anchor-width]"
                  >
                    <DropdownMenuItem>
                      <SidebarMenuButton tooltip="Account" asChild>
                        <Link to="/my-account">
                          <BadgeCheck />
                          <span>Account</span>
                        </Link>
                      </SidebarMenuButton>
                    </DropdownMenuItem>
                    {isFeatureEnabled('integrators') && (
                      <DropdownMenuItem>
                        <SidebarMenuButton tooltip="Integrators" asChild>
                          <Link to="/integrators">
                            <Puzzle />
                            <span>Integrators</span>
                          </Link>
                        </SidebarMenuButton>
                      </DropdownMenuItem>
                    )}
                    <DropdownMenuItem>
                      <SidebarMenuButton tooltip="Billing" asChild>
                        <Link to="/billing">
                          <CreditCard />
                          <span>Billing</span>
                        </Link>
                      </SidebarMenuButton>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <SidebarMenuButton tooltip="Resources" asChild>
                        <Link to="/resources">
                          <Settings />
                          <span>Resources</span>
                        </Link>
                      </SidebarMenuButton>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <SidebarMenuButton>
                        <Link to="/saved-filters" className="flex items-center gap-2">
                          <Filter className="h-4 w-4" />
                          <span>Saved Filters</span>
                        </Link>
                      </SidebarMenuButton>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem>
                      <SidebarMenuButton tooltip="Sign out" asChild>
                        <Link to="/signout">
                          <LogOut />
                          <span>Log out</span>
                        </Link>
                      </SidebarMenuButton>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarFooter>
      </Sidebar>
    </>
  );
}