import { 
  ArrayAttribute,
  AttributeCounts, 
  ScalarAttribute,
  ProductFilter,
  IdSet,
  PgProduct
} from '../shared/types';
import {
  SCALAR_ATTRIBUTES,
  ARRAY_ATTRIBUTES
} from '../shared/constants'
import { toUTCDayBoundary } from '../shared/utils';
import { pgQuery } from './apiService';


export interface ArrayAttributeCountParams {
  entityType: ArrayAttribute;
  fromDate: Date | null;
  toDate: Date | null;
  limit?: number;
  offset?: number;
  productFilter?: ProductFilter;
  specificValues?: string[];
}

export interface ScalarAttributeCountParams {
  attributeName: ScalarAttribute;
  fromDate: Date | null;
  toDate: Date | null;
  limit?: number;
  offset?: number;
  productFilter?: ProductFilter;
  specificValues?: string[];
}

export interface IndicatorTotalCountsParams {
  fromDate: Date | null;
  toDate: Date | null;
  productFilter?: ProductFilter;
}

export interface IndicatorTypeCountParams {
  fromDate: Date | null;
  toDate: Date | null;
  limit?: number;
  offset?: number;
}



export const getArrayAttributeCount = async ({
  entityType,
  fromDate,
  toDate,
  limit = 10,
  offset = 0,
  productFilter,
  specificValues
}: ArrayAttributeCountParams): Promise<AttributeCounts> => {
  const entityLower = entityType.toLowerCase();
  const tableName = `product_${entityLower}`;
  const joinTableName = entityLower === 'tags' ? 'tags' : entityLower;
  let idColumn;
  if (entityLower === 'tags') {
    idColumn = 'tag_id';
  } else if (entityLower === 'validators') {
    idColumn = 'validator_id';
  } else {
    idColumn = `${entityLower}_id`;
  }

  let query = `
    SELECT e.name AS item, COUNT(DISTINCT pe.product_id) AS count
    FROM ${joinTableName} e
    JOIN ${tableName} pe ON e.id = pe.${idColumn}
    JOIN products p ON pe.product_id = p.id
    WHERE ($1::timestamp IS NULL OR p.timestamp >= $1::timestamp)
      AND ($2::timestamp IS NULL OR p.timestamp <= $2::timestamp)
  `;

  const values: any[] = [
    toUTCDayBoundary(fromDate, false),
    toUTCDayBoundary(toDate, true)
  ];

  if (productFilter) {
    const filterConditions = [];
    
    if (productFilter.category) {
      filterConditions.push(` AND p.category = ANY($${values.length + 1}::text[])`);
      values.push(productFilter.category);
    }
    if (productFilter.source) {
      filterConditions.push(` AND p.source = ANY($${values.length + 1}::text[])`);
      values.push(productFilter.source);
    }

    ARRAY_ATTRIBUTES.forEach(attr => {
      if (productFilter[attr]) {
        // Fix the column name for tags specifically
        const idColumn = attr.toLowerCase() === 'tags' ? 'tag_id' : `${attr.toLowerCase()}_id`;
        filterConditions.push(` AND EXISTS (
          SELECT 1 FROM product_${attr.toLowerCase()} pa
          JOIN ${attr.toLowerCase()} a ON pa.${idColumn} = a.id
          WHERE pa.product_id = p.id AND a.name = ANY($${values.length + 1}::text[])
        )`);
        values.push(productFilter[attr]);
      }
    });

    // Add indicator filter condition
    if (productFilter.indicators && productFilter.indicators.length > 0) {
      filterConditions.push(` AND EXISTS (
        SELECT 1 FROM product_indicators pi
        JOIN indicators i ON pi.indicator_id = i.id
        WHERE pi.product_id = p.id AND i.name = ANY($${values.length + 1}::text[])
      )`);
      values.push(productFilter.indicators);
    }

    const filterSQL = filterConditions.join('');
    query += filterSQL;
  }

  // Add specificValues condition if provided
  if (specificValues && specificValues.length > 0) {
    query += ` AND e.name = ANY($${values.length + 1}::text[])`;
    values.push(specificValues);
  }

  // Modify the ORDER BY and LIMIT clause
  if (specificValues) {
    // If we have specific values, don't use LIMIT/OFFSET
    query += ` GROUP BY e.id, e.name ORDER BY count DESC, item ASC`;
  } else {
    // Use original LIMIT/OFFSET for initial fetching
    query += `
      GROUP BY e.id, e.name
      ORDER BY count DESC, item ASC
      LIMIT $${values.length + 1}
      OFFSET $${values.length + 2}
    `;
    values.push(limit);
    values.push(offset);
  }

  try {
    const result: { item: string; count: string }[] = await pgQuery(query, values);
    const counts: AttributeCounts = {
      [entityType]: result.reduce((acc, { item, count }) => {
        acc[item] = parseInt(count, 10);
        return acc;
      }, {} as Record<string, number>)
    };
    return counts;
  } catch (error) {
    console.error(`Error fetching ${entityType} items:`, error);
    throw error;
  }
}


export const getScalarAttributeCount = async ({
  attributeName,
  fromDate,
  toDate,
  limit = 10,
  offset = 0,
  productFilter,
  specificValues
}: ScalarAttributeCountParams): Promise<AttributeCounts> => {

  if (!SCALAR_ATTRIBUTES.includes(attributeName)) {
    throw new Error(`Invalid attribute name: ${attributeName}`);
  }

  let query = `
    SELECT ${attributeName} AS item, COUNT(*) AS count
    FROM products p
    WHERE ($1::timestamp IS NULL OR p.timestamp >= $1::timestamp)
      AND ($2::timestamp IS NULL OR p.timestamp <= $2::timestamp)
  `;

  const values: any[] = [
    toUTCDayBoundary(fromDate, false),
    toUTCDayBoundary(toDate, true)
  ];

  if (productFilter) {
    const filterConditions = [];
    
    if (productFilter.category) {
      filterConditions.push(` AND p.category = ANY($${values.length + 1}::text[])`);
      values.push(productFilter.category);
    }
    if (productFilter.source) {
      filterConditions.push(` AND p.source = ANY($${values.length + 1}::text[])`);
      values.push(productFilter.source);
    }

    ARRAY_ATTRIBUTES.forEach(attr => {
      if (productFilter[attr]) {
        const idColumn = attr.toLowerCase() === 'tags' ? 'tag_id' : `${attr.toLowerCase()}_id`;
        filterConditions.push(` AND EXISTS (
          SELECT 1 FROM product_${attr.toLowerCase()} pa
          JOIN ${attr.toLowerCase()} a ON pa.${idColumn} = a.id
          WHERE pa.product_id = p.id AND a.name = ANY($${values.length + 1}::text[])
        )`);
        values.push(productFilter[attr]);
      }
    });

    // Add indicator filter condition
    if (productFilter.indicators && productFilter.indicators.length > 0) {
      filterConditions.push(` AND EXISTS (
        SELECT 1 FROM product_indicators pi
        JOIN indicators i ON pi.indicator_id = i.id
        WHERE pi.product_id = p.id AND i.name = ANY($${values.length + 1}::text[])
      )`);
      values.push(productFilter.indicators);
    }

    const filterSQL = filterConditions.join('');
    query += filterSQL;
  }

  // Add specificValues condition if provided
  if (specificValues && specificValues.length > 0) {
    query += ` AND ${attributeName} = ANY($${values.length + 1}::text[])`;
    values.push(specificValues);
  }

  // Modify the ORDER BY and LIMIT clause
  if (specificValues) {
    // If we have specific values, don't use LIMIT/OFFSET
    query += ` GROUP BY p.${attributeName} ORDER BY count DESC, item ASC`;
  } else {
    // Use original LIMIT/OFFSET for initial fetching
    query += `
      GROUP BY p.${attributeName}
      ORDER BY count DESC, item ASC
      LIMIT $${values.length + 1}
      OFFSET $${values.length + 2}
    `;
    values.push(limit);
    values.push(offset);
  }

  try {
    const result: { item: string; count: string }[] = await pgQuery(query, values);
    const counts: AttributeCounts = {
      [attributeName]: result.reduce((acc, { item, count }) => {
        acc[item] = parseInt(count, 10);
        return acc;
      }, {} as AttributeCounts)
    };
    return counts;
  } catch (error) {
    console.error(`Error fetching ${attributeName} counts:`, error);
    throw error;
  }
}


export const getIndicatorTypeCountFilter = async ({
  fromDate,
  toDate,
  productFilter
}: IndicatorTotalCountsParams): Promise<AttributeCounts> => {
  let query = `
    SELECT 
      i.name AS indicator_name,
      COUNT(DISTINCT pi.product_id) AS total_count
    FROM indicators i
    JOIN product_indicators pi ON i.id = pi.indicator_id
    JOIN products p ON pi.product_id = p.id
    WHERE ($1::timestamp IS NULL OR p.timestamp >= $1::timestamp)
      AND ($2::timestamp IS NULL OR p.timestamp <= $2::timestamp)
  `;

  const values: any[] = [
    toUTCDayBoundary(fromDate, false),
    toUTCDayBoundary(toDate, true)
  ];

  if (productFilter) {
    const filterConditions = [];
    
    if (productFilter.category) {
      filterConditions.push(` AND p.category = ANY($${values.length + 1}::text[])`);
      values.push(productFilter.category);
    }
    if (productFilter.source) {
      filterConditions.push(` AND p.source = ANY($${values.length + 1}::text[])`);
      values.push(productFilter.source);
    }

    ARRAY_ATTRIBUTES.forEach(attr => {
      if (productFilter[attr]) {
        // Fix the column name for tags specifically
        const idColumn = attr.toLowerCase() === 'tags' ? 'tag_id' : `${attr.toLowerCase()}_id`;
        filterConditions.push(` AND EXISTS (
          SELECT 1 FROM product_${attr.toLowerCase()} pa
          JOIN ${attr.toLowerCase()} a ON pa.${idColumn} = a.id
          WHERE pa.product_id = p.id AND a.name = ANY($${values.length + 1}::text[])
        )`);
        values.push(productFilter[attr]);
      }
    });

    const filterSQL = filterConditions.join('');
    query += filterSQL;
  }

  query += ` GROUP BY i.name ORDER BY i.name ASC`;

  try {
    const result = await pgQuery(query, values);
    return {
      indicators: result.reduce((acc, { indicator_name, total_count }) => {
        acc[indicator_name] = parseInt(total_count, 10);
        return acc;
      }, {} as Record<string, number>)
    };
  } catch (error) {
    console.error('Error fetching indicator total counts:', error);
    throw error;
  }
}


export const getIndicatorTypeCountMV = async ({
  fromDate,
  toDate,
  limit = 10,
  offset = 0
}: IndicatorTypeCountParams): Promise<AttributeCounts> => {
  const query = `
    WITH summed_counts AS (
      SELECT 
        indicator_name,
        SUM(product_count) as total_count
      FROM mv_daily_indicator_counts
      WHERE ($1::timestamp IS NULL OR day >= DATE_TRUNC('day', $1::timestamp))
        AND ($2::timestamp IS NULL OR day <= DATE_TRUNC('day', $2::timestamp))
      GROUP BY indicator_name
    )
    SELECT *
    FROM summed_counts
    ORDER BY total_count DESC, indicator_name ASC
    LIMIT $3
    OFFSET $4
  `;

  const values = [
    toUTCDayBoundary(fromDate, false),
    toUTCDayBoundary(toDate, true),
    limit,
    offset
  ];

  try {
    const result = await pgQuery(query, values);
    return {
      indicators: result.reduce((acc, { indicator_name, total_count }) => {
        acc[indicator_name] = parseInt(total_count, 10);
        return acc;
      }, {} as Record<string, number>)
    };
  } catch (error) {
    console.error('Error fetching indicator type counts:', error);
    throw error;
  }
};


export const getFilteredProductCount = async (
  productFilter: ProductFilter,
  fromDate: Date,
  toDate: Date
): Promise<number> => {
  let query = `
    SELECT COUNT(DISTINCT p.id) as product_count
    FROM products p
  `;

  const values = [
    toUTCDayBoundary(fromDate, false)!.toISOString(),
    toUTCDayBoundary(toDate, true)!.toISOString(),
    productFilter.category || null,
    productFilter.source || null,
  ];

  // Add joins for ARRAY_ATTRIBUTES
  ARRAY_ATTRIBUTES.forEach((attr, index) => {
    if (productFilter[attr]) {
      const tableName = `product_${attr.toLowerCase()}`;
      const joinTableName = attr.toLowerCase();
      const idColumn = attr.toLowerCase() === 'tags' ? 'tag_id' : `${attr.toLowerCase()}_id`;
      query += `
        JOIN ${tableName} pa${index} ON p.id = pa${index}.product_id
        JOIN ${joinTableName} a${index} ON pa${index}.${idColumn} = a${index}.id
      `;
    }
  });

  query += `
    WHERE p.timestamp BETWEEN $1 AND $2
      AND ($3::text[] IS NULL OR p.category = ANY($3))
      AND ($4::text[] IS NULL OR p.source = ANY($4))
  `;

  // Add conditions for ARRAY_ATTRIBUTES
  ARRAY_ATTRIBUTES.forEach((attr, index) => {
    if (productFilter[attr]) {
      query += ` AND a${index}.name = ANY($${values.length + 1}::text[])`;
      values.push(productFilter[attr]);
    }
  });

  // Add EXISTS clause for indicators (OR condition)
  if (productFilter.indicators && productFilter.indicators.length > 0) {
    query += ` AND EXISTS (
      SELECT 1 FROM product_indicators pi
      JOIN indicators i ON pi.indicator_id = i.id
      WHERE pi.product_id = p.id AND i.name = ANY($${values.length + 1}::text[])
    )`;
    values.push(productFilter.indicators);
  }

  try {
    const result = await pgQuery(query, values);
    const count = parseInt(result[0].product_count, 10);
    return count;
  } catch (error) {
    console.error('Error fetching filtered product count:', error);
    throw error;
  }
}


export const getFilteredProductIds = async (
  productFilter: ProductFilter,
  fromDate: Date,
  toDate: Date,
  page: number = 1,
  pageSize: number = 20
): Promise<IdSet[]> => {
  let query = `
    SELECT DISTINCT p.id, p.truss_prod_id, p.timestamp
    FROM products p
  `;

  const values = [
    toUTCDayBoundary(fromDate, false)!.toISOString(),
    toUTCDayBoundary(toDate, true)!.toISOString(),
    productFilter.category || null,
    productFilter.source || null,
  ];

  // Add joins for ARRAY_ATTRIBUTES
  ARRAY_ATTRIBUTES.forEach((attr, index) => {
    if (productFilter[attr]) {
      const tableName = `product_${attr.toLowerCase()}`;
      const joinTableName = attr.toLowerCase();
      const idColumn = attr.toLowerCase() === 'tags' ? 'tag_id' : `${attr.toLowerCase()}_id`;
      query += `
        JOIN ${tableName} pa${index} ON p.id = pa${index}.product_id
        JOIN ${joinTableName} a${index} ON pa${index}.${idColumn} = a${index}.id
      `;
    }
  });

  query += `
    WHERE p.timestamp BETWEEN $1 AND $2
      AND ($3::text[] IS NULL OR p.category = ANY($3))
      AND ($4::text[] IS NULL OR p.source = ANY($4))
  `;

  // Add conditions for ARRAY_ATTRIBUTES
  ARRAY_ATTRIBUTES.forEach((attr, index) => {
    if (productFilter[attr]) {
      query += ` AND a${index}.name = ANY($${values.length + 1}::text[])`;
      values.push(productFilter[attr]);
    }
  });

  // Add EXISTS clause for indicators (OR condition)
  if (productFilter.indicators && productFilter.indicators.length > 0) {
    query += ` AND EXISTS (
      SELECT 1 FROM product_indicators pi
      JOIN indicators i ON pi.indicator_id = i.id
      WHERE pi.product_id = p.id AND i.name = ANY($${values.length + 1}::text[])
    )`;
    values.push(productFilter.indicators);
  }

  // Add ORDER BY, LIMIT, and OFFSET clauses
  query += `
    ORDER BY p.timestamp DESC
    LIMIT $${values.length + 1} OFFSET $${values.length + 2}
  `;

  const offset = (page - 1) * pageSize;
  values.push(pageSize.toString(), offset.toString());

  try {
    const productIds = await pgQuery(query, values);
    return productIds;
  } catch (error) {
    console.error('Error fetching filtered product IDs:', error);
    throw error;
  }
}


export const getProducts = async (
  productIds: number[],
  page: number,
  pageSize: number
): Promise<{ products: PgProduct[], totalCount: number }> => {
  const offset = (page - 1) * pageSize;
  const query = `
    WITH product_data AS (
      SELECT p.*,
        (SELECT array_agg(a.name) FROM product_author pa JOIN author a ON pa.author_id = a.id WHERE pa.product_id = p.id) AS author,
        (SELECT array_agg(i.name) FROM product_industry pi JOIN industry i ON pi.industry_id = i.id WHERE pi.product_id = p.id) AS industry,
        (SELECT array_agg(r.name) FROM product_region pr JOIN region r ON pr.region_id = r.id WHERE pr.product_id = p.id) AS region,
        (SELECT array_agg(ref.name) FROM product_reference pref JOIN reference ref ON pref.reference_id = ref.id WHERE pref.product_id = p.id) AS reference,
        (SELECT array_agg(t.name) FROM product_tags pt JOIN tags t ON pt.tag_id = t.id WHERE pt.product_id = p.id) AS tags,
        (SELECT array_agg(v.name) FROM product_validators pv JOIN validators v ON pv.validator_id = v.id WHERE pv.product_id = p.id) AS validators,
        (SELECT jsonb_object_agg(i.name, 
           (SELECT array_agg(pi2.value) 
            FROM product_indicators pi2 
            WHERE pi2.product_id = p.id 
            AND pi2.indicator_id = i.id)
         )
         FROM product_indicators pi
         JOIN indicators i ON pi.indicator_id = i.id
         WHERE pi.product_id = p.id
         GROUP BY p.id) AS indicators
      FROM products p
      WHERE p.id = ANY($1)
    )
    SELECT *
    FROM product_data
    ORDER BY ARRAY_POSITION($1, id)
    LIMIT $2 OFFSET $3
  `;

  const values = [productIds, pageSize, offset];

  try {
    const result: PgProduct[] = await pgQuery(query, values);
    return { products: result, totalCount: productIds.length };
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};



