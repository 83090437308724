export const MAX_DATE_RANGE = 7;

export const SCALAR_ATTRIBUTES = ['category', 'source'] as const;
export const ARRAY_ATTRIBUTES = ['author', 'industry', 'region', 'reference', 'tags'] as const;
export const INDICATOR_ATTRIBUTES = ['indicators'] as const;
export const PRODUCT_ATTRIBUTES = [...SCALAR_ATTRIBUTES, ...ARRAY_ATTRIBUTES, ...INDICATOR_ATTRIBUTES] as const;

export const apiKey = process.env.REACT_APP_TRUSS_API_KEY || '';
export const apiUrl = process.env.REACT_APP_API_URL || '';
export const trussApi = "https://api.truss-security.com";

export const regionNameMap: Record<string, string> = {
  "United States": "United States of America",
  "Hong Kong": "Hong Kong S.A.R.",
  "Ivory Coast": "Ivory Coast",
  "Russia": "Russia", // Updated to match GeoJSON name
  "Syria": "Syria",
  "Iran": "Iran",
  "Czech Republic": "Czech Republic",
  "Moldova": "Moldova",
  "South Korea": "South Korea",
  "North Korea": "North Korea",
  "Vietnam": "Vietnam",
  "Bolivia": "Bolivia",
  "Venezuela": "Venezuela",
  "United Kingdom": "United Kingdom",
  "Democratic Republic of the Congo": "Democratic Republic of the Congo",
  "Republic of Congo": "Republic of Congo",
  "Myanmar": "Myanmar",
  "Palestine": "Palestine",
  "Laos": "Laos",
  "Tanzania": "United Republic of Tanzania",
  "Swaziland": "Swaziland",
  "Cape Verde": "Cape Verde",
  "Brunei": "Brunei",
  "Macau": "Macao S.A.R",
  "East Timor": "East Timor",
  "Micronesia": "Federated States of Micronesia",
  // Additional mappings based on the GeoJSON country list
  "Federated States of Micronesia": "Federated States of Micronesia",
  "Saint Kitts and Nevis": "Saint Kitts and Nevis",
  "Saint Vincent and the Grenadines": "Saint Vincent and the Grenadines",
  "Saint Lucia": "Saint Lucia",
  "Antigua and Barbuda": "Antigua and Barbuda",
  "Bosnia and Herzegovina": "Bosnia and Herzegovina",
  "North Macedonia": "North Macedonia",
  "Central African Republic": "Central African Republic",
  "Equatorial Guinea": "Equatorial Guinea",
  "Trinidad and Tobago": "Trinidad and Tobago",
  "Papua New Guinea": "Papua New Guinea",
  //... continue mapping as needed
};

export const USER_TYPES = {
  CONTRIBUTOR: {
    planId: 'jrgoli',
    accessType: 'contributor'
  },
  COMMUNITY: {
    planId: 'po0bdp',
    accessType: 'community'
  },
  USER: {
    planId: 'ycbyvm',
    accessType: 'user'
  },
  TEST: {
    planId: 'gxhpyq',
    accessType: 'test'
  },
  TRUSS: {
    planId: 'a0eqgv',
    accessType: 'truss'
  }
} as const;

export type UsagePlanId = (typeof USER_TYPES)[keyof typeof USER_TYPES]['planId'];
export type AccessType = (typeof USER_TYPES)[keyof typeof USER_TYPES]['accessType'];