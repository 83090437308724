import { createClient, AuthResponse } from '@supabase/supabase-js'
import { callAdminApi } from './apiService'
import {  AccessType, USER_TYPES } from '../shared/constants'
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL!
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY!

export type TrussUsers = {
  id: string;
  api_key: string;
  api_key_id: string;
  username: string;
  role: AccessType;
  filters: any[];  // Consider typing these more specifically
  charts: any[];
  liked_authors: string[];
  liked_products: string[];
  liked_sources: string[];
  subscriptions: any[];
  bio?: string;
  avatar_url?: string;
  updated_at?: string;
}

export type AccessCodes = {
  id: string;
  api_key: string | null;
  claimed: boolean;
  claimed_at: Date | null;
  email: string | null;
}

export type TrussApiKeys = {
  id: string;
  user_id: string;
  api_key_id: string;
  api_key: string;
  access_type: 'free' | 'premium';
  created_at: string;
}

type ApiKeyData = {
  apiKeyId: string;
  apiKey: string;
}

export type TrussSubscription = {
  id: string;
  user_id: string;
  current_period_end: string;
  status: string;
}

export const supabaseClient = createClient(supabaseUrl, supabaseAnonKey, {
    auth: {
        persistSession: true
    },
    db: {
        schema: 'public'
    }
})

// All users start as community users
export const signUpUser = async (email: string, password: string) => {
    try {
        // Step 1: Create auth user in Supabase
        const authResponse: AuthResponse = await supabaseClient.auth.signUp({
            email,
            password,
            options: {
                emailRedirectTo: `${window.location.origin}/login`,
            }
        });

        if (authResponse.error) {
            if (authResponse.error.message.includes('Email already registered')) {
                return { 
                    user: null, 
                    apiKey: null,
                    message: 'Email already registered. Please check your email for the confirmation link.'
                };
            }
            throw authResponse.error;
        }
        if (!authResponse.data.user) throw new Error('User creation failed - no user data returned');
        
        // Step 2: Create API key in Truss API
        const apiKeyData: ApiKeyData = await callAdminApi<ApiKeyData>(
            '/api-key/create',
            'POST',
            {
                userId: authResponse.data.user.id,
                email: authResponse.data.user.email,
                accessType: USER_TYPES.COMMUNITY.accessType,
                usagePlanId: USER_TYPES.COMMUNITY.planId
            }
        );

        // Step 3: Create user record
        const newUser: Omit<TrussUsers, 'updated_at'> = {
            id: authResponse.data.user.id,
            api_key: apiKeyData.apiKey,
            api_key_id: apiKeyData.apiKeyId,
            username: email.split('@')[0],
            role: USER_TYPES.COMMUNITY.accessType,
            filters: [],
            charts: [],
            liked_authors: [],
            liked_products: [],
            liked_sources: [],
            subscriptions: []
        };

        const { error: dbError } = await supabaseClient
            .from('truss_users')
            .insert([newUser]);

        if (dbError) throw dbError;

        return { user: authResponse.data.user, apiKey: apiKeyData.apiKey };

    } catch (error) {
        console.error('Signup error details:', error);
        if (typeof error === 'object' && error !== null && 'code' in error && error.code === '23505') {
            throw new Error('This email is already registered but not confirmed. Please check your email for the confirmation link.');
        }
        if (error instanceof Error) {
            throw error;
        } else {
            throw new Error(`An unexpected error occurred during signup. Please try again.`);
        }
    }
};

export const fetchUserDataFromSupabase = async () => {
    const { data: { user } } = await supabaseClient.auth.getUser();
    if (!user) throw new Error('No user found');

    const { data: userData, error: userError } = await supabaseClient
        .from('truss_users')
        .select('*')
        .eq('id', user.id)
        .single();

    if (userError) {
        console.error('Error fetching user:', userError);
        throw userError;
    }

    if (!userData) {
        throw new Error('User data not found');
    }

    return userData;
};

export const updateUserDataInSupabase = async (updates: {
    username?: string;
    bio?: string;
    avatarUrl?: string;
}) => {
    const { data: { user }, error: userError } = await supabaseClient.auth.getUser();
    if (userError) throw userError;
    if (!user) throw new Error('No user found');

    const supabaseUpdates: Partial<TrussUsers> = {
        username: updates.username,
        bio: updates.bio,
        avatar_url: updates.avatarUrl,
        updated_at: new Date().toISOString()
    };

    // Remove undefined values
    const filteredUpdates = Object.fromEntries(
        Object.entries(supabaseUpdates).filter(([_, value]) => value !== undefined)
    ) as Partial<TrussUsers>;

    const { error: supabaseError, data } = await supabaseClient
        .from('truss_users')
        .update(filteredUpdates)
        .eq('id', user.id)
        .select()
        .single();

    if (supabaseError) {
        console.error('Update error:', supabaseError);
        throw supabaseError;
    }

    return data;
};

export const updateUserPassword = async (newPassword: string) => {
    const { error } = await supabaseClient.auth.updateUser({
        password: newPassword
    });

    if (error) throw error;
};

export const sendPasswordResetEmail = async (email: string) => {
    const { error } = await supabaseClient.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
    });

    if (error) throw error;
};

export const updatePasswordWithToken = async (newPassword: string) => {
    const { error } = await supabaseClient.auth.updateUser({
        password: newPassword
    });

    if (error) throw error;
};

export const rotateApiKey = async (apiKeyId: string) => {
    const { data: { user } } = await supabaseClient.auth.getUser();
    if (!user) throw new Error('No user found');

    const { newApiKey } = await callAdminApi<{ newApiKey: string }>(
        '/api-key/rotate',
        'POST',
        { apiKeyId }
    );

    // Update the API key in Supabase
    const { error } = await supabaseClient
        .from('api_keys')
        .update({ api_key: newApiKey })
        .eq('api_key_id', apiKeyId)
        .eq('user_id', user.id);

    if (error) throw error;

    return newApiKey;
};

export const fetchUserSubscription = async (): Promise<TrussSubscription | null> => {
    const { data: { user } } = await supabaseClient.auth.getUser();
    if (!user) throw new Error('No user found');

    const { data: subscription, error } = await supabaseClient
        .from('subscriptions')
        .select('*')
        .eq('user_id', user.id)
        .eq('status', 'active')
        .single();

    if (error) {
        if (error.code === 'PGRST116') { // No rows returned
            return null;
        }
        throw error;
    }

    return subscription;
}; 